import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { QRCodeModule } from 'angularx-qrcode';
import { IonicImageLoaderModule } from 'ionic-image-loader-v5';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { SaleComponent } from '../sales/sale/sale.component';
import { QzTrayComponent } from '../settings/printer/qz-tray/qz-tray.component';
import { ViewLabelsComponent } from '../settings/printer/view/labels/view-labels.component';
import { ViewLineComponent } from '../settings/printer/view/line/view-line.component';
import { ViewMoveComponent } from '../settings/printer/view/move/view-move.component';
import { ViewPresaleComponent } from '../settings/printer/view/presale/view-presale.component';
import { ViewRefillSalePricesComponent } from '../settings/printer/view/refill-sale-prices/view-refill-sale-prices.component';
import { ViewRefillComponent } from '../settings/printer/view/refill/view-refill.component';
import { ViewSaleComponent } from '../settings/printer/view/sale/view-sale.component';
import { ViewShiftTotalsComponent } from '../settings/printer/view/shift-totals/view-shift-totals.component';
import { ViewTaxReportComponent } from '../settings/printer/view/tax-report/view-tax-report.component';
import { ViewTaxServiceDocComponent } from '../settings/printer/view/tax-service-doc/view-tax-service-doc.component';
import { ViewDialog } from '../settings/printer/view/view.dialog';
import { ViewWasteComponent } from '../settings/printer/view/waste/view-waste.component';
import { InvoicesComponent } from '../warehouse/invoices/invoices.component';

import { BluetoothBarcodeScannerComponent } from './components/bluetooth-barcode-scanner/bluetooth-barcode-scanner.component';
import { CachedImageComponent } from './components/cached-image/cached-image.component';
import { ConfirmDialog } from './components/confirm-dialog/confirm.dialog';
import { CoverComponent } from './components/cover/cover.component';
import { IconComponent } from './components/icon/icon-component';
import { LogViewComponent } from './components/log-view/log-view.component';
import { MultibarcodeDialog } from './components/multibarcode-dialog/multibarcode.dialog';
import { SelectFromTableDialog } from './dialogs/select-from-table/select-from-table.dialog';
import { FocusRemoverDirective } from './directives/focus-remover.directive';
import { IonicNgxMaskDirective } from './directives/ionic-ngx-mask.directive';
import { IonicNgxPrefixDirective } from './directives/ionic-ngx-prefix.directive';
import { MaxValidator } from './validators/max.validator';
import { MinValidator } from './validators/min.validator';

export const options: Partial<IConfig> | (() => Partial<IConfig>) | null = null;

const imports: any[] = [
  IonicModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  IonicImageLoaderModule,
  QRCodeModule,
  NgxMaskModule.forRoot(),
];

const declarations: any[] = [
  MinValidator,
  MaxValidator,
  ConfirmDialog,
  CoverComponent,
  ViewLineComponent,
  IconComponent,
  BluetoothBarcodeScannerComponent,
  CachedImageComponent,
  FocusRemoverDirective,
  IonicNgxMaskDirective,
  IonicNgxPrefixDirective,
  MultibarcodeDialog,
  SelectFromTableDialog,
  ViewDialog,
  ViewSaleComponent,
  ViewTaxReportComponent,
  ViewTaxServiceDocComponent,
  ViewShiftTotalsComponent,
  ViewWasteComponent,
  ViewPresaleComponent,
  ViewMoveComponent,
  ViewRefillComponent,
  ViewRefillSalePricesComponent,
  ViewLabelsComponent,
  SaleComponent,
  LogViewComponent,
  InvoicesComponent,
  QzTrayComponent,
];

@NgModule({
  imports,
  declarations,
  exports: [...imports, ...declarations],
})
export class SharedModule {}
