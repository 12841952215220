<ion-header>
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-searchbar  name="textSearchbar"
                  placeholder="Введіть одне або декілька слів для пошуку"
                  [(ngModel)]="searchItem"
                  (ionCancel)="clearTextSearchbar()"
                  (ionChange)="filterList()"
                  (ionFocus)="selectAll()"></ion-searchbar>

  <div class="table-header">
    <span class="column-name">Назва</span>
  </div>

  <ion-content class="items">
    <ion-list lines="none" class="table-data">
      <div class="even-row" *ngFor="let item of filteredItems; let index = index">
        <ion-item [button]="true" detail="false" (click)="select(item)"> 
          <div class="table-data-row">
            <div class="column-name">
              {{ item.name }}
            </div>
          </div>
        </ion-item>
      </div>
    </ion-list>
  </ion-content>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button color="tertiary" class="one-button" (click)="cancel()">
      <ion-label>Скасувати</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>