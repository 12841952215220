<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="settings"></ion-back-button>
    </ion-buttons>

    <ion-title>Другий екран</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-segment color="dark" (ionChange)="changedTab($event)" [value]="activePlugin">
    <ion-segment-button [value]="TAB_ESB_WINDOW_POSITIONER">
      <ion-label>{{ TAB_ESB_WINDOW_POSITIONER.title }}</ion-label>
    </ion-segment-button>

    <ion-segment-button [value]="TAB_MULTIWINDOW_POSITIONER">
      <ion-label>{{ TAB_MULTIWINDOW_POSITIONER.title }}</ion-label>
    </ion-segment-button>
  </ion-segment>

  <ion-list [inset]="true" lines="full" class="settings">
    <ion-list-header>
      <ion-label>Налаштування екрану клієнта</ion-label>
    </ion-list-header>

    <ion-item button detail="false" (click)="installPlugin()">
      <ion-icon slot="start" name="cloud-download-outline" color="secondary"></ion-icon>
      <ion-text>
        <ion-label>Встановіть плагін для Google Chrome або Microsoft Edge</ion-label>
        <ion-note>Mozilla Firefox та Safari не підтримують цей функціонал</ion-note>
      </ion-text>
    </ion-item>

    <section *ngIf="activePlugin.name === TAB_MULTIWINDOW_POSITIONER.name">
      <ion-item button detail="false" (click)="openSettings()">
        <ion-icon slot="start" name="settings-outline" color="secondary"></ion-icon>
        <ion-text>
          <ion-label>Відкрийте налаштування плагіна</ion-label>
          <ion-note>Меню "⋮" - Розширення - Керувати розширеннями - Знайти плагін - Деталі - Параметри розширень</ion-note>
        </ion-text>
      </ion-item>

      <ion-item button detail="false" (click)="copyToClipboard()">
        <ion-icon slot="start" name="copy-outline" color="secondary"></ion-icon>
        <ion-text>
          <ion-label>Скопіюйте шлях до шаблону налаштування</ion-label>
          <ion-note>Натисніть тут щоб скопіювати шлях</ion-note>
        </ion-text>
      </ion-item>
  
      <ion-item detail="false">
        <ion-icon slot="start" name="download-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>У вікні плагіна знайдіть і натисніть кнопку "Import Template"</ion-label>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="information-circle-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Вставте скопійований шлях у поле "Import Template" та натисніть "ADD"</ion-label>
          <ion-note>Рекомендується активувати "Replace all templates" </ion-note>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="add-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Натисніть "Add Tab Option"</ion-label>
          <ion-note>Кнопка зі значком "+" поруч з "Import Template"</ion-note>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="information-circle-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Виберіть шаблон "cashbox Client Screen" зі списку "Template"</ion-label>
          <ion-note>Рекомендується активувати "Remember" </ion-note>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="desktop-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Виберіть монітор зі списку "Monitor" та натисніть "ADD"</ion-label>
          <ion-note>Наприклад: 2 Generic PnP Monitor</ion-note>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="save-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Натисніть кнопку "Save" щоб зберегти налаштування плагіна</ion-label>
          <ion-note>Якщо налаштування оновлені, але не збережені - вгорі є напис "[Draft]"</ion-note>
        </ion-text>
      </ion-item>
    </section>

    <section *ngIf="activePlugin.name === TAB_ESB_WINDOW_POSITIONER.name">
      <ion-item button detail="false" (click)="copyToClipboard()">
        <ion-icon slot="start" name="copy-outline" color="secondary"></ion-icon>
        <ion-text>
          <ion-label>Скопіюйте посилання на екран клієнта</ion-label>
          <ion-note>Натисніть тут щоб скопіювати шлях</ion-note>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="extension-puzzle-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Відкрийте налаштування плагіна</ion-label>
          <ion-note>Кнопка "Розширення" ("🧩") на панелі інструментів - Знайти плагін - Натиснути на назву</ion-note>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="information-circle-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Вставте скопійоване посилання у поле "URL Rule"</ion-label>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="desktop-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Виберіть монітор зі списку "Target Display"</ion-label>
          <ion-note>Наприклад: 2 Generic PnP Monitor</ion-note>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="resize-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Виберіть "Fullscreen" зі списку "Window State"</ion-label>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="save-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Натисніть кнопку "Save & Close" щоб зберегти налаштування плагіна</ion-label>
        </ion-text>
      </ion-item>

      <ion-item detail="false">
        <ion-icon slot="start" name="attach-outline" color="medium"></ion-icon>
        <ion-text>
          <ion-label>Значок плагіна можна закріпити на панелі інструментів</ion-label>
          <ion-note>Натисніть кнопку "📌" біля назви плагіна</ion-note>
        </ion-text>
      </ion-item>
    </section>

    <ion-item detail="false">
      <ion-icon slot="start" name="information-circle-outline" color="medium"></ion-icon>
      <ion-text>
        <ion-label>Оновіть сторінку cashbox</ion-label>
      </ion-text>
    </ion-item>
  </ion-list>
</ion-content>
