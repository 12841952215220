<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button slot="icon-only" shape="round" class="back-button" (click)="cancelModal()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>

    <div class="logo-container">
      <img class="header-logo" src="assets/images/logo.png" alt="Логотип" (click)="cancelModal()">
    </div>

    <ion-searchbar *ngIf="searchMode"
                    #searchbar
                    id="searchbar"
                    name="searchbar"
                    placeholder="Введіть текст для пошуку клієнта"
                    class="payment-searchbar"
                    debounce="500"
                    [(ngModel)]="searchQuery"
                    (ionBlur)="clearSearchbar()"
                    (ionCancel)="clearSearchbar()"
                    (ionChange)="filterList()"></ion-searchbar>

    <ion-buttons slot="end">
      <section *ngIf="tariff?.discount && !saleInDebt">
        <ion-button (click)="showSearchbar()">
          <ion-icon color="secondary" name="search"></ion-icon>
        </ion-button>
  
        <ion-button *ngIf="loyalty" (click)="removeClient()">
          <ion-icon color="primary" name="close"></ion-icon>
        </ion-button>
  
        <ion-button *ngIf="!loyalty" (click)="findClient()">
          <ion-icon color="secondary" name="sync"></ion-icon>
        </ion-button>
      </section>

      <ion-button (click)="toggleShowCommentMode()">
        <ion-icon color="secondary" [name]="userSettings?.showCommentInPayment ? 'chatbox-ellipses' : 'chatbox-ellipses-outline'"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="search-select-list">
    <ion-item *ngFor="let loyalty of loyalties" (click)="selectClient(loyalty)">
      <ion-label>{{ loyalty.client.name }} {{ loyaltyCaption(loyalty) }}</ion-label>
      <ion-label class="ion-text-end">{{ loyalty.client.mobilePhone | mask: '+00 000 000 0000' }}</ion-label>
    </ion-item>
  </ion-list>

  <main class="payment">
    <section class="payment__check">
      <div class="payment__check__client" *ngIf="loyalty">
        <div class="payment__check__client__row">
          <div class="payment__check__client__row__name">
            {{ loyalty.client.name ? loyalty.client.name : 'Немає імені'}}
          </div>

          <div class="payment__check__client__row__value">
            <span *ngIf="loyalty.wholesaleBuyer; else personalDiscount_section">
              Оптовий покупець
            </span>

            <ng-template #personalDiscount_section>
              <span *ngIf="loyalty.personalDiscount; else bonus_section">
                Знижка: до {{ loyalty.personalDiscount }} %
              </span>

              <ng-template #bonus_section>
                <span *ngIf="loyalty.bonus; else notes">
                  Бонуси: {{ loyalty.bonus | currency:'UAH':'symbol-narrow' }}
                </span>

                <ng-template #notes>
                  <span *ngIf="saleInDebt">
                    Погашення боргу
                  </span>
                </ng-template>
              </ng-template>
            </ng-template>
          </div>
        </div>

        <div class="payment__check__client__row">
          <div class="payment__check__client__row__name">{{ loyalty.client.mobilePhone | mask: '+00 000 000 0000' }}</div>
          <div class="payment__check__client__row__value">
            <span *ngIf="loyalty.balance">
              <ion-label>Баланс: </ion-label>
              <ion-label [ngClass]="{ 'debt': loyalty.balance < 0 }">{{ loyalty.balance | currency:'UAH':'symbol-narrow' }}</ion-label>
            </span>
          </div>
        </div>

        <div class="payment__check__client__row" *ngIf="user.saleInDebt && !saleInDebt">
          <ion-button [color]="isInDebt ? 'primary' : 'medium'" [fill]="isInDebt ? 'solid' : 'outline'" (click)="setDebt()">
            <ion-icon slot="start" name="trending-down-outline"></ion-icon>
            <ion-label>В борг</ion-label>
          </ion-button>
        </div>
      </div>

      <div class="payment__check__summary">
        <div class="payment__check__summary__row">
          <div class="payment__check__summary__row__data">
            <div class="payment__check__summary__row__data__name">Всього</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label>
                {{ totalSum | number:'1.2-2' }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">₴</div>
          </div>

          <div class="payment__check__summary__row__button"></div>
        </div>

        <div class="payment__check__summary__row" *ngIf="(loyalty && loyalty.personalDiscount) || (totalDiscount && !totalUserDiscount)">
          <div class="payment__check__summary__row__data">
            <div class="payment__check__summary__row__data__name">Знижка</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label [color]="totalDiscount ? 'success' : 'day'">
                {{ totalDiscount | number:'1.2-2' }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">
              <ion-label [color]="totalDiscount ? 'success' : 'day'">₴</ion-label>
            </div>
          </div>

          <div class="payment__check__summary__row__button"></div>
        </div>

        <div class="payment__check__summary__row" *ngIf="!loyalty && user?.changeSaleSum && (totalUserDiscount || totalUserOverPrice)">
          <div class="payment__check__summary__row__data">
            <div class="payment__check__summary__row__data__name">
              {{ totalUserDiscount > 0 ? 'Знижка' : 'з надбавкою' }}
            </div>

            <div class="payment__check__summary__row__data__value">
              <ion-label [color]="totalUserDiscount > 0 ? 'success' : 'day'">
                {{ (totalUserDiscount > 0 ? totalUserDiscount : totalUserOverPrice) | number:'1.2-2' }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">
              <ion-label [color]="totalUserDiscount > 0 ? 'success' : 'day'">₴</ion-label>
            </div>
          </div>

          <div class="payment__check__summary__row__button">
            <ion-button icon-only fill="outline" shape="round" size="small" (click)="resetUserDiscount()">
              <ion-icon name="close"></ion-icon>
            </ion-button>
          </div>
        </div>

        <div class="payment__check__summary__row" *ngIf="loyalty && !(loyalty.personalDiscount || loyalty.wholesaleBuyer || saleInDebt)">
          <div  class="payment__check__summary__row__data"
                [ngClass]="{ 'focused': activeFocus === focus.Bonus }"
                (click)="activateFocus(focus.Bonus)">

            <div class="payment__check__summary__row__data__name">Бонуси</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label class="edit-sum" [color]="totalBonusPayment ? 'success' : 'day'">
                {{ activeFocus === focus.Bonus ? totalBonusPaymentText : (totalBonusPayment | number:'1.2-2') }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">
              <ion-label [color]="totalBonusPayment ? 'success' : 'day'">₴</ion-label>
            </div>
          </div>

          <div class="payment__check__summary__row__button">
            <ion-button icon-only fill="outline" shape="round" size="small" (click)="resetBonus()">
              <ion-icon [name]="totalBonusPayment === 0 ? 'checkmark' : 'close'"></ion-icon>
            </ion-button>
          </div>
        </div>

        <div class="payment__check__summary__row" *ngIf="loyalty && totalFreeCups">
          <div class="payment__check__summary__row__data">
            <div class="payment__check__summary__row__data__name">Товари у подарунок</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label color="success">
                {{ totalFreeCups | number:'1.2-2' }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">₴</div>
          </div>

          <div class="payment__check__summary__row__button"></div>
        </div>

        <div class="payment__check__summary__row" *ngIf="totalRound">
          <div class="payment__check__summary__row__data">
            <div class="payment__check__summary__row__data__name">Заокруглення</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label>
                {{ totalRound | number:'1.2-2' }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">₴</div>
          </div>

          <div class="payment__check__summary__row__button"></div>
        </div>

        <div class="payment__check__summary__row">
          <div  class="payment__check__summary__row__data"
                [ngClass]="{ 'focused': activeFocus === focus.Payment && !loyalty && user?.changeSaleSum }"
                (click)="activateFocus(focus.Payment)">

            <div class="payment__check__summary__row__data__name">До оплати</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label [ngClass]="{ 'edit-sum': !loyalty && user?.changeSaleSum }" color="primary">
                {{ activeFocus === focus.Payment ? totalPaymentText : (totalPayment | number:'1.2-2') }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">
              <ion-label color="primary">₴</ion-label>
            </div>
          </div>

          <div class="payment__check__summary__row__button"></div>
        </div>

        <div class="payment__check__summary__row" *ngIf="!isInDebt">
          <div  class="payment__check__summary__row__data"
                [ngClass]="{ 'focused': activeFocus === focus.Card }"
                (click)="activateFocus(focus.Card)">

            <div class="payment__check__summary__row__data__name">Картка</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label class="edit-sum" [color]="totalCard ? 'success' : 'day'">
                {{ activeFocus === focus.Card ? totalCardText : (totalCard | number:'1.2-2') }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">
              <ion-label [color]="totalCard ? 'success' : 'day'">₴</ion-label>
            </div>
          </div>

          <div class="payment__check__summary__row__button">
            <ion-button icon-only fill="outline" shape="round" size="small" (click)="resetCard()" *ngIf="totalCard">
              <ion-icon name="close"></ion-icon>
            </ion-button>
          </div>
        </div>

        <div class="payment__check__summary__row" *ngIf="!isInDebt">
          <div  class="payment__check__summary__row__data"
                [ngClass]="{ 'focused': activeFocus === focus.Cash }"
                (click)="activateFocus(focus.Cash)">

            <div class="payment__check__summary__row__data__name">Готівка</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label class="edit-sum" [color]="totalCash ? 'success' : 'day'">
                {{ activeFocus === focus.Cash ? totalCashText : (totalCash | number:'1.2-2') }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">
              <ion-label [color]="totalCash ? 'success' : 'day'">₴</ion-label>
            </div>
          </div>

          <div class="payment__check__summary__row__button">
            <ion-button icon-only fill="outline" shape="round" size="small" (click)="resetCash()" *ngIf="totalCash">
              <ion-icon name="close"></ion-icon>
            </ion-button>
          </div>
        </div>

        <div class="payment__check__summary__row" *ngIf="!isInDebt">
          <div  class="payment__check__summary__row__data"
                [ngClass]="{ 'focused': activeFocus === focus.ClientCash }"
                (click)="activateFocus(focus.ClientCash)">

            <div class="payment__check__summary__row__data__name">Отримано</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label class="edit-sum" [color]="clientCash ? 'success' : 'day'">
                {{ activeFocus === focus.ClientCash ? clientCashText : (clientCash | number:'1.2-2') }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">
              <ion-label [color]="clientCash ? 'success' : 'day'">₴</ion-label>
            </div>
          </div>

          <div class="payment__check__summary__row__button">
            <ion-button icon-only fill="outline" shape="round" size="small" (click)="resetClientCash()" *ngIf="clientCash">
              <ion-icon name="close"></ion-icon>
            </ion-button>
          </div>
        </div>

        <div class="payment__check__summary__row" *ngIf="totalChange">
          <div class="payment__check__summary__row__data">
            <div class="payment__check__summary__row__data__name">Решта</div>

            <div class="payment__check__summary__row__data__value">
              <ion-label>
                {{ totalChange | number:'1.2-2' }}
              </ion-label>
            </div>

            <div class="payment__check__summary__row__data__currency">₴</div>
          </div>

          <div class="payment__check__summary__row__button"></div>
        </div>

        <div class="payment__check__summary__divider" *ngIf="shop?.prroMode === shopPRroMode.UserMode && !isInDebt"></div>

        <div class="payment__check__summary__row" *ngIf="shop?.prroMode === shopPRroMode.UserMode && !isInDebt">
          <div class="payment__check__summary__row__option">
            <div class="payment__check__summary__row__option__name">Фіскалізація</div>

            <div class="payment__check__summary__row__option__value">
              <ion-toggle slot="end" color="success" [(ngModel)]="isNeedFiscalization"></ion-toggle>
            </div>
          </div>

          <div class="payment__check__summary__row__button"></div>
        </div>
      </div>

      <div class="payment__check__comment">
        <ion-item *ngIf="userSettings?.showCommentInPayment">
          <ion-textarea type="text"
                        id="comment"
                        name="comment"
                        placeholder="Коментар"
                        [autoGrow]="true"
                        [minlength]="0"
                        [bkMin]="0"
                        [maxlength]="MAX_STRING_LENGTH"
                        [bkMax]="MAX_STRING_LENGTH"
                        [(ngModel)]="comment"></ion-textarea>

          <ion-button *ngIf="comment"
                      icon-only
                      color="medium"
                      fill="outline"
                      shape="round"
                      size="small"
                      (click)="clearComment()">
            <ion-icon name="close"></ion-icon>
          </ion-button>

          <ion-button *ngIf="!comment"
                      icon-only
                      color="medium"
                      fill="outline"
                      shape="round"
                      size="small"
                      (click)="setDelivery()">
            <ion-icon name="home"></ion-icon>
          </ion-button>
        </ion-item>

        <ion-item [hidden]="true">
          <ion-label>Час замовлення:</ion-label>
          <ion-datetime #datetime
                        name="date-picker"
                        displayFormat="DD MMMM HH:mm"
                        cancelText="Скасувати​"
                        doneText="Підтвердити"
                        placeholder="Час замовлення"
                        hourValues="5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23"
                        minuteValues="0,5,10,15,20,25,30,35,40,45,50,55"
                        [monthNames]="UA_MONTHS"
                        [min]="minDate"
                        [max]="maxDate"
                        [(ngModel)]="presaleDate" 
                        (ionChange)="dateTimeChange()"
                        (ionCancel)="dateTimeCancel()"></ion-datetime>
        </ion-item>
      </div>
    </section>

    <section class="payment__numpad">
      <div class="payment__numpad__label" [innerHTML]="paymentHint"></div>

      <ion-grid>
        <ion-row *ngFor="let row of numpad">
          <ion-col *ngFor="let key of row">
            <ion-button color="medium" fill="outline" expand="block" (click)="onKeyPress(key)">
              <ion-label>{{ key }}</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>
  </main>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button class="one-button"
                color="primary"
                (click)="finish(false)"
                *ngIf="isInDebt; else defaultButtons">
      <ion-icon slot="start" name="trending-down-outline"></ion-icon>
      <ion-label>Підтвердити</ion-label>
    </ion-button>

    <ng-template #defaultButtons>
      <ion-button class="presale-button" color="tertiary"
                  (click)="createPresale()"
                  *ngIf="shop?.presales && !presale && !saleInDebt">
        <ion-icon slot="start" name="alarm-outline"></ion-icon>
        <ion-label>Відкласти</ion-label>
      </ion-button>
  
      <ion-button class="finish-button"
                  [ngStyle]="finishButtonStyle"
                  color="primary"
                  (click)="finish(isNeedFiscalization)">
        <ion-icon slot="start" [name]="isNeedFiscalization ? 'qr-code-outline' : 'wallet-outline'"></ion-icon>
        <ion-label>{{ shop?.prroManualControl || isNeedFiscalization ? 'Видати чек' : 'Оплачено'}}</ion-label>
      </ion-button>
  
      <ion-button class="non-fiscal-button"
                  color="secondary"
                  (click)="finish(false)"
                  [disabled]="totalCard > 0"
                  *ngIf="shop?.prroManualControl && isPRroActive">
        <ion-icon slot="start" name="wallet-outline"></ion-icon>
        <ion-label>Оплачено</ion-label>
      </ion-button>
    </ng-template>
  </ion-toolbar>
</ion-footer>
