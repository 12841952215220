import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ActionSheetButton,
  ActionSheetController,
  AlertController,
  IonItemSliding,
  ModalController,
} from '@ionic/angular';
import cloneDeep from 'lodash-es/cloneDeep';
import round from 'lodash-es/round';

import { SALE_RETURN_DIALOG_ID } from '../core/constants/modal-controller.const';
import { SALE_GROUP_ROWS } from '../core/constants/visible-rows.const';
import { ShopPRroMode } from '../core/enum/shop-p-rro-mode.enum';
import { Shift } from '../core/models/shift.model';
import { Shop } from '../core/models/shop.model';
import { User } from '../core/models/user.model';
import { CachedDataService } from '../core/services/cached-data.service';
import { LoadingService } from '../core/services/loading.service';
import { ShiftsService } from '../core/services/resources/shifts.service';
import { UserService } from '../core/services/resources/user.service';
import { ToastService } from '../core/services/toast.service';
import { UtilsService } from '../core/services/utils.service';
import { CheckDocumentSubType } from '../p-rro/fsco/enums/check-document-sub-type.enum';
import { PayFormCode } from '../p-rro/fsco/enums/pay-form-code.enum';
import { ShiftState } from '../p-rro/fsco/enums/shift-state.enum';
import { PRroService } from '../p-rro/p-rro.service';
import { LabelPrinterService } from '../settings/printer/label/label-printer.service';
import { ReceiptPrinterService } from '../settings/printer/receipt/receipt-printer.service';
import { CONFIRM_DIALOG_ALERT_STYLE } from '../settings/settings.const';

import { ReturnService } from './return.service';
import { SaleReturnDialog } from './sale-return/sale-return.dialog';
import { Sale } from './sale/sale.model';
import { SalesGroup } from './sales-group.model';
import { SalesService } from './sales.service';
import { SaleSearchDialog } from './search/sale-search.dialog';

const LOADING_ID = 'sales.component';
const RETURN_TITLE = 'Повернення';

interface FiscalizationOptions {
  fiscalizeAll: boolean;
}

@Component({
  selector: 'bk-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
  @ViewChild(IonItemSliding) itemSliding: IonItemSliding;

  saleGroups: SalesGroup[] = [];
  sales: Sale[];
  shift: Shift;
  shop: Shop;
  user: User;

  requestInProgress = false;
  isShiftRefreshing = false;
  isPRroActive = false;
  isDesktop = false;
  isAndroidApp = false;
  isPrintLabelMode = false;

  // Returns a Promise that resolves after "ms" Milliseconds
  timer = (ms: number) => new Promise((res) => setTimeout(res, ms));

  constructor(
    private actionSheetController: ActionSheetController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private cachedDataService: CachedDataService,
    private utilsService: UtilsService,
    private salesService: SalesService,
    private returnService: ReturnService,
    private shiftsService: ShiftsService,
    private userService: UserService,
    private prroService: PRroService,
    private receiptPrinterService: ReceiptPrinterService,
    private labelPrinterService: LabelPrinterService,
  ) {}

  ngOnInit(): void {
    this.isPRroActive = this.cachedDataService.isPRroActive();
    this.isDesktop = this.utilsService.isDesktop();
    this.isAndroidApp = this.utilsService.isAndroidApp();

    this.shop = this.cachedDataService.getShop();

    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  ionViewWillEnter(): void {
    this.refreshShiftData();

    this.saleGroups = [];

    this.salesService
      .find({
        shiftId: this.shiftsService.getCurrentShiftId(),
        forApp: true,
      })
      .subscribe((sales) => {
        this.sales = sales;

        this.sales.forEach((sale) => {
          if (
            this.saleGroups.find(
              (group) => group.date === sale.createdAt.toLocaleDateString(),
            ) == null
          ) {
            this.saleGroups.push(
              new SalesGroup(sale.createdAt.toLocaleDateString()),
            );
          }
        });

        this.regroupSales(this.sales);

        if (this.isPRroActive) {
          this.checkFiscalization({ fiscalizeAll: false }).then(() => {
            //
          });
        }
      });

    this.labelPrinterService.status().then((status) => {
      this.isPrintLabelMode = status.isPrinterAvailable;
    });
  }

  private async checkFiscalization(
    options: FiscalizationOptions,
  ): Promise<void> {
    const incorrectFiscalizationConditions =
      await this.incorrectFiscalizationConditions(options);

    if (incorrectFiscalizationConditions) {
      return;
    }

    let total = 0;
    let success = 0;

    for (let i = this.saleGroups.length - 1; i >= 0; i -= 1) {
      for (let j = this.saleGroups[i].sales.length - 1; j >= 0; j -= 1) {
        const sale = this.saleGroups[i].sales[j];

        if (this.needSkip(sale, options)) {
          continue;
        }

        total += 1;

        await this.startLoadingPreloader();

        const isFiscalized = await this.salesService.addFiscalization(sale);

        if (!isFiscalized) {
          await this.endOfOperation();

          this.showFiscalizationReport(options, {
            total,
            success,
            failed: true,
          });

          return;
        }

        success += 1;

        await this.timer(this.randomNumber(2000, 5000));
      }

      await this.timer(this.randomNumber(2000, 5000));
    }

    if (this.requestInProgress) {
      await this.endOfOperation();
    }

    this.showFiscalizationReport(options, {
      total,
      success,
      failed: false,
    });
  }

  private async startLoadingPreloader(
    spinnerName: string = LOADING_ID,
  ): Promise<void> {
    if (!this.requestInProgress) {
      this.requestInProgress = true;

      await this.loadingService.presentCustomPreloader(
        spinnerName,
        'Оновлення даних...',
      );
    }
  }

  private async incorrectFiscalizationConditions(
    options: FiscalizationOptions,
  ): Promise<boolean> {
    if (
      !options.fiscalizeAll &&
      this.shop.prroMode !== ShopPRroMode.StandardMode
    ) {
      return true;
    }

    const warningTitle = 'Операцію буде скасовано';
    const warnings: string[] = [];

    if (!this.isPRroActive) {
      warnings.push('ПРРО не активовано');
    } else if (this.requestInProgress) {
      warnings.push('Триває інша операція. Зачекайте...');
    }

    const prroState = await this.prroService.getPRROState();

    if (prroState == null) {
      warnings.push('Не вдалося отримати з ДПС стан ПРРО');
    }

    const prroShift = this.cachedDataService.getPRROShift();

    if (prroShift == null) {
      warnings.push('Не вдалося отримати стан ПРРО');
    } else if (prroShift.ShiftState === ShiftState.Closed) {
      warnings.push('Фіскальна зміна закрита');
    } else if (prroShift.ZRepPresent) {
      warnings.push('Z-звіт вже створено');
    }

    if (warnings.length > 0) {
      if (options.fiscalizeAll) {
        this.toastService.presentWarning(warningTitle, warnings.join('\n'));
      }

      return true;
    }

    return false;
  }

  private needSkip(sale: Sale, options: FiscalizationOptions): boolean {
    if (Math.abs(sale.debtSum) > 0) {
      return true;
    }

    if (this.isFiscalizedDoc(sale)) {
      return true;
    }

    if (!options.fiscalizeAll && !this.needFiscalization(sale)) {
      return true;
    }

    return false;
  }

  private needFiscalization(sale: Sale): boolean {
    return (
      (sale.prroTaxNumber ?? '') === '' &&
      (!this.shop.prroManualControl || sale.cashless || sale.cashless == null)
    );
  }

  private showFiscalizationReport(
    options: FiscalizationOptions,
    report: {
      total: number;
      success: number;
      failed: boolean;
    },
  ): void {
    if (!options.fiscalizeAll) {
      return;
    }

    if (report.failed) {
      this.toastService.presentWarning('Не вдалося фіскалізувати всі чеки');
    }

    if (report.total === 0) {
      this.toastService.present('Немає чеків для фіскалізації');
    } else {
      this.toastService.present(
        `Фіскалізовано чеків: ${report.success} з ${report.total}`,
      );
    }
  }

  showNonCashSaleCount(): void {
    let nonCashSaleCount = 0;

    this.saleGroups.forEach((saleGroup) => {
      nonCashSaleCount += saleGroup.sales.filter(
        (s) => s.cardSum > 0 || s.cardSum < 0,
      ).length;
    });

    this.toastService.present(
      'Продажі',
      `Кількість чеків з оплатою карткою: ${nonCashSaleCount}`,
    );
  }

  async showCashboxCalculation(): Promise<void> {
    const shift = await this.shiftsService.getShiftReport();

    if (shift == null) {
      return;
    }

    await this.receiptPrinterService.printShiftTotals(shift, {
      viewMode: true,
    });
  }

  async openMenu(): Promise<void> {
    const actionSheetButtons: ActionSheetButton[] = [
      {
        text: 'Скасувати',
        icon: 'close',
        role: 'cancel',
      },
    ];

    if (this.isPRroActive) {
      actionSheetButtons.push({
        text: 'Фіскалізувати всі чеки',
        icon: 'qr-code-outline',
        cssClass: 'tax-all-sales',
        handler: () => {
          this.fiscalizeAllAlert();
        },
      });
    }

    actionSheetButtons.push({
      text: RETURN_TITLE,
      icon: 'bag-remove-outline',
      cssClass: 'return',
      handler: () => {
        this.findSale();
      },
    });

    if (this.user?.showCash) {
      actionSheetButtons.push({
        text: 'Звіт за зміну',
        icon: 'print-outline',
        cssClass: 'report',
        handler: () => {
          this.showCashboxCalculation();
        },
      });
    }

    const actionSheet = await this.actionSheetController.create({
      header: 'Додаткові операції',
      cssClass: 'sales-action-sheet-controller',
      buttons: actionSheetButtons,
    });

    await actionSheet.present();
  }

  private refreshShiftData(): void {
    this.isShiftRefreshing = true;

    this.shiftsService.getShift().subscribe((shift) => {
      if (shift != null) {
        this.shift = shift;
      }

      this.isShiftRefreshing = false;
    });
  }

  async fiscalizeAllAlert(): Promise<void> {
    if (!this.isPRroActive) {
      this.toastService.presentWarning(
        'Програмний РРО не активний',
        'Перевірте налаштування криптобібліотеки або дані торгової точки',
      );

      return;
    }

    if (this.requestInProgress) {
      this.toastService.presentWarning('Триває інша операція. Зачекайте...');

      return;
    }

    const alert = await this.alertCtrl.create({
      header: 'Синхронізація даних з ДПС',
      message: `<strong>Відправити</strong> всі нефіскалізовані чеки у ДПС?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.requestInProgress = false;
          },
        },
        {
          text: 'Відправити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            await this.checkFiscalization({ fiscalizeAll: true });
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  async findSale(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: SaleSearchDialog,
      componentProps: {},
      backdropDismiss: false,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.sale != null) {
      this.returnSaleAlert(data.sale, this.saleGroups[0]);
    }
  }

  async qr(sale: Sale): Promise<void> {
    this.itemSliding.close();

    if (this.requestInProgress) {
      return;
    }

    if (sale.prroLocalNumber != null && (sale.prroTaxNumber ?? '') > '') {
      await this.prroService.showQR(sale);
    } else {
      this.requestInProgress = true;

      const alert = await this.alertCtrl.create({
        header: 'Синхронізація даних з ДПС',
        message: `<strong>Відправити</strong> чек у ДПС?`,
        buttons: [
          {
            text: 'Скасувати',
            role: 'cancel',
            cssClass: 'tertiary',
            handler: () => {
              this.requestInProgress = false;
            },
          },
          {
            text: 'Відправити',
            role: 'confirm',
            cssClass: 'primary',
            handler: async () => {
              await this.createQR(sale);

              this.requestInProgress = false;
            },
          },
        ],
        cssClass: CONFIRM_DIALOG_ALERT_STYLE,
      });

      await alert.present();
    }
  }

  private async createQR(sale: Sale): Promise<boolean> {
    return this.salesService.addFiscalization(sale, {
      printReceipt: this.shop.printCheck,
    });
  }

  async printReceipt(sale: Sale): Promise<void> {
    this.itemSliding.close();

    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    if (
      this.isPRroActive &&
      this.shop.prroManualControl &&
      (sale.prroTaxNumber ?? '') === '' &&
      !(Math.abs(sale.debtSum) > 0)
    ) {
      await this.salesService.addFiscalization(sale);
    }

    await this.receiptPrinterService.printSale(sale, { viewMode: true });

    this.requestInProgress = false;
  }

  async printLabels(sale: Sale): Promise<void> {
    this.itemSliding.close();

    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    this.labelPrinterService.printSale(sale, { viewMode: true });

    this.requestInProgress = false;
  }

  async changePaymentMethodAlert(sale: Sale, group: SalesGroup): Promise<void> {
    this.itemSliding.close();

    if (this.requestInProgress || sale.cashless == null) {
      return;
    }

    this.requestInProgress = true;

    const alert = await this.alertCtrl.create({
      header: 'Тип оплати чека',
      message: `<strong>Змінити</strong> тип оплати на "${
        sale.cashless ? 'Готівка' : 'Картка'
      }"?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.requestInProgress = false;
          },
        },
        {
          text: 'Змінити',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            await this.changePaymentMethod(sale, group);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  private async changePaymentMethod(
    sale: Sale,
    group: SalesGroup,
  ): Promise<void> {
    const debtPayment = sale.salePayments.find(
      (salePayment) => salePayment.method === PayFormCode.InDebt,
    );

    if (debtPayment != null) {
      this.toastService.presentError(
        'Зміна типу оплати',
        'Операція недоступна для накладних у борг',
      );

      return;
    }

    const cardPayment = sale.salePayments.find(
      (sp) => sp.method === PayFormCode.Card,
    );

    const isCard = cardPayment != null;

    const newSale = this.getNewSale(sale, isCard);

    const terminalData = await this.salesService.cashlessPaymentDialog(
      isCard ? -1 * sale.cardSum : newSale.cardSum,
      cardPayment?.acquirerTransactionId,
    );

    if (terminalData == null) {
      this.requestInProgress = false;

      return;
    }

    await this.loadingService.presentCustomPreloader(LOADING_ID);

    const returnSale = await this.returnService.create(
      sale,
      isCard ? terminalData : undefined,
    );

    if (returnSale == null) {
      await this.loadingService.dismiss(LOADING_ID);

      this.requestInProgress = false;

      return;
    }

    group.sales.splice(0, 0, ...[returnSale]);
    group.visibleRows += 1;

    this.returnService.addPayments(
      newSale,
      {
        debt: newSale.debtSum,
        card: newSale.cardSum,
        cash: newSale.cashSum,
      },
      isCard ? undefined : terminalData,
    );

    newSale.calcTotalTaxes();

    this.salesService
      .createSale(newSale, CheckDocumentSubType.CheckGoods, {
        needFiscalization: (sale.prroTaxNumber ?? '') > '',
      })
      .then((createdSale) => {
        this.salesService.setPaymentIcon(newSale);

        group.sales.splice(0, 0, ...[newSale]);
        group.visibleRows += 1;

        this.refreshShiftData();
        this.refreshGroup(group);
      })
      .finally(async () => {
        await this.loadingService.dismiss(LOADING_ID);

        this.requestInProgress = false;
      });
  }

  private getNewSale(sale: Sale, isCard: boolean): Sale {
    const newSale = new Sale();

    if (sale.prro != null) {
      newSale.prro = sale.prro;
    }

    if (sale.client != null) {
      newSale.clientId = sale.client.id;
      newSale.client = sale.client;
    }

    if (sale.saleInDebtId != null) {
      newSale.saleInDebtId = sale.saleInDebtId;
    }

    newSale.saleProducts = [];

    sale.saleProducts.forEach((sp) => {
      const saleProduct = cloneDeep(sp);

      newSale.totalDiscount += saleProduct.discount ?? 0;
      newSale.totalFreeCups += saleProduct.freeCup ?? 0;
      newSale.totalBonus += saleProduct.bonusPayment ?? 0;
      newSale.saleProducts.push(saleProduct);
    });

    const totalSum = newSale.saleProducts.reduce(
      (result, saleProduct) =>
        result +
        round(round(saleProduct.price, 2) * round(saleProduct.quantity, 3), 2),
      0,
    );

    newSale.discountSum = round(
      newSale.totalDiscount + newSale.totalFreeCups + newSale.totalBonus,
      2,
    );

    newSale.costSum = round(totalSum + newSale.discountSum, 2);

    if (isCard) {
      newSale.paymentSum = round(newSale.costSum - newSale.discountSum, 2);
      newSale.roundSum = this.utilsService.getRoundSum(newSale.paymentSum);
      newSale.paymentSum = round(newSale.paymentSum + newSale.roundSum, 2);
      newSale.cashSum = newSale.paymentSum;
      newSale.cardSum = 0;
      newSale.debtSum = 0;
    } else {
      newSale.roundSum = 0;
      newSale.paymentSum = round(newSale.costSum - newSale.discountSum, 2);
      newSale.cashSum = 0;
      newSale.cardSum = newSale.paymentSum;
      newSale.debtSum = 0;
    }

    return newSale;
  }

  async returnSaleAlert(sale: Sale, group: SalesGroup): Promise<void> {
    this.itemSliding?.close();

    if (this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    const alert = await this.alertCtrl.create({
      header: RETURN_TITLE,
      message: `<strong>Виконати</strong> повернення чека?`,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
          handler: () => {
            this.requestInProgress = false;
          },
        },
        {
          text: 'Виконати',
          role: 'confirm',
          cssClass: 'primary',
          handler: async () => {
            await this.returnSale(sale, group);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  private async returnSale(sale: Sale, group: SalesGroup): Promise<void> {
    if (
      sale.salePayments.find((sp) => sp.method === PayFormCode.InDebt) != null
    ) {
      const debtRepayments = await this.salesService.findDebtRepayments(
        sale.id,
      );

      if (debtRepayments.length > 0) {
        this.toastService.presentWarning(
          RETURN_TITLE,
          'Борг було погашено, повернення чеку неможливе',
        );

        this.requestInProgress = false;

        return;
      }
    }

    const returnDialog = await this.modalCtrl.create({
      component: SaleReturnDialog,
      componentProps: {
        sale,
      },
      backdropDismiss: false,
      id: SALE_RETURN_DIALOG_ID,
    });

    await returnDialog.present();

    const { data: returnData } = await returnDialog.onWillDismiss();

    if (returnData.returnSale == null) {
      this.requestInProgress = false;

      return;
    }

    const returnSale = returnData.returnSale as Sale;

    if (group == null) {
      group = new SalesGroup(returnSale.createdAt.toLocaleDateString());

      group.collapsed = false;

      this.saleGroups.push(group);
    }

    group.sales.splice(0, 0, ...[returnSale]);
    group.visibleRows += 1;

    this.refreshShiftData();
    this.refreshGroup(group);

    this.requestInProgress = false;
  }

  private async endOfOperation(
    spinnerName: string = LOADING_ID,
  ): Promise<void> {
    await this.loadingService.dismiss(spinnerName);

    this.requestInProgress = false;
  }

  toggleGroupSale(group: SalesGroup): void {
    group.collapsed = !group.collapsed;

    if (group.collapsed) {
      group.visibleRows = SALE_GROUP_ROWS;
    }
  }

  showMoreSales(group: SalesGroup): void {
    group.visibleRows = Math.min(
      group.sales.length,
      group.visibleRows + SALE_GROUP_ROWS,
    );
  }

  isFiscalMode(sale: Sale): boolean {
    return this.isPRroActive && this.isFiscalizedDoc(sale);
  }

  isFiscalizedDoc(sale: Sale): boolean {
    return sale.prroLocalNumber != null && (sale.prroTaxNumber ?? '') > '';
  }

  private regroupSales(sales: Sale[]): void {
    this.saleGroups.forEach((saleGroup, index) => {
      if (index === 0) {
        this.saleGroups[0].collapsed = false;
      }

      sales.forEach((sale) => {
        if (sale.createdAt.toLocaleDateString() === saleGroup.date) {
          this.calcGroupSalePayments(saleGroup, sale);

          saleGroup.sales.push(sale);
        }
      });

      saleGroup.visibleRows = Math.min(saleGroup.sales.length, SALE_GROUP_ROWS);
    });
  }

  private refreshGroup(group: SalesGroup): void {
    group.cashSum = 0;
    group.cardSum = 0;

    group.sales.forEach((sale) => {
      this.calcGroupSalePayments(group, sale);
    });
  }

  private calcGroupSalePayments(group: SalesGroup, sale: Sale): void {
    const cashPayment = sale.salePayments.find(
      (sp) => sp.method === PayFormCode.Cash,
    );

    const cardPayment = sale.salePayments.find(
      (sp) => sp.method === PayFormCode.Card,
    );

    if (cashPayment) {
      group.cashSum += cashPayment.amount;
    }

    if (cardPayment) {
      group.cardSum += cardPayment.amount;
    }
  }

  private randomNumber(min: number, max: number): number {
    const minValue: number = Math.ceil(min);
    const maxValue: number = Math.floor(max);

    return Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
  }
}
