import { Sale } from '../sales/sale/sale.model';

import { Client } from './client.model';

export class ClientBonus {
  id: number;
  shopId: number;
  clientId: number;
  client: Client;
  bonus: number;
  personalDiscount: number;
  wholesaleBuyer: boolean;
  balance: number;
  salesBalance: number;

  // App fields
  collapsed: boolean;
  salesInDebt?: Sale[];
}
