import { Injectable } from '@angular/core';
import round from 'lodash-es/round';

import { SETTINGS_UPDATED } from '../../../core/constants/events.const';
import { IStorageUserSettings } from '../../../core/interfaces/storage-user-setting.interface';
import { CachedDataService } from '../../../core/services/cached-data.service';
import { Events } from '../../../core/services/events.service';
import { UtilsService } from '../../../core/services/utils.service';
import { SaleProduct } from '../../../sales/sale/sale-product.model';

import { InvoiceProductsTotals } from './invoice-products.interface';

@Injectable({
  providedIn: 'root',
})
export class InvoiceProductsService {
  private userSettings: IStorageUserSettings;

  constructor(
    private events: Events,
    private utilsService: UtilsService,
    private cachedDataService: CachedDataService,
  ) {
    this.userSettings = this.cachedDataService.getUserSettings();

    this.events.subscribe(
      SETTINGS_UPDATED,
      (userSettings: IStorageUserSettings) => {
        this.userSettings = userSettings;
      },
    );
  }

  getTotals(saleProducts: SaleProduct[]): InvoiceProductsTotals {
    const result = {
      amount: 0,
      roundedAmount: 0,
      isRounded: false,
    };

    try {
      result.amount = saleProducts.reduce(
        (amount, saleProduct) =>
          amount +
          round(
            round(saleProduct.price, 2) * round(saleProduct.quantity, 3),
            2,
          ),
        0,
      );

      if (this.userSettings.roundInvoiceTotalSum) {
        const roundedSum = this.utilsService.getRoundSum(result.amount);

        result.roundedAmount = result.amount + roundedSum;
        result.isRounded = Math.abs(roundedSum) > 0;
      } else {
        result.roundedAmount = result.amount;
      }
    } catch (e) {
      //
    }

    return result;
  }
}
