import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

import { Resource } from '../../abstract/resource';
import {
  ERROR_OFFLINE_SALE,
  NO_INTERNET_TITLE,
} from '../../constants/error-messages.const';
import { Company } from '../../models/company.model';
import { Shop } from '../../models/shop.model';
import { Tariff } from '../../models/tariff.model';
import { AuthService } from '../auth.service';
import { CachedDataService } from '../cached-data.service';
import { ToastService } from '../toast.service';
import { UtilsService } from '../utils.service';

import { ShopsService } from './shops.service';

@Injectable({
  providedIn: 'root',
})
export class TariffService extends Resource<Company> {
  private company: Company;
  private maxActiveDays = 30;
  private companyId: number;
  private shopId: number;
  private tariff: Tariff | null;

  constructor(
    protected http: HttpClient,
    private cachedDataService: CachedDataService,
    private shopsService: ShopsService,
    private authService: AuthService,
    private utilsService: UtilsService,
    private toastService: ToastService,
  ) {
    super(http, {
      path: '/companies',
    });
  }

  async canActivatePage(): Promise<Promise<boolean> | boolean> {
    this.shopId = this.cachedDataService.getShopId();

    if (this.shopId == null) {
      return false;
    }

    if (this.company == null) {
      await this.checkCompany();
    }

    return this.checkTariff(this.company.tariffExpirationDate);
  }

  async getCurrentTariff(): Promise<Tariff> {
    const isOnline = await this.utilsService.isOnline();

    if (!isOnline) {
      this.toastService.present(NO_INTERNET_TITLE, ERROR_OFFLINE_SALE, 5000);

      const noDiscount = new Tariff();

      noDiscount.discount = false;

      return noDiscount;
    }

    if (this.tariff != null) {
      return this.tariff;
    }

    if (this.company == null) {
      await this.checkCompany();
    }

    return this.http
      .get(`/tariffs/${this.company.tariffId}`)
      .toPromise()
      .then((tariff: Tariff) => {
        this.tariff = tariff;
        return tariff;
      });
  }

  private async checkCompany(): Promise<void> {
    if (this.authService.getCompanyId() >= 0) {
      this.companyId = this.authService.getCompanyId();
    } else {
      await this.shopsService
        .get(this.shopId)
        .toPromise()
        .then((shop: Shop) => {
          this.companyId = Number(shop.companyId);
        });
    }

    await this.get(this.companyId)
      .toPromise()
      .then((company: Company) => {
        this.company = company;
      });
  }

  private checkTariff(tariffExpiration: string): boolean {
    const tariffExpirationDate = DateTime.fromISO(tariffExpiration);
    const currentDate = DateTime.local();

    const daysDiff =
      this.maxActiveDays -
      Math.floor(
        Number(tariffExpirationDate.diff(currentDate, 'days').toObject().days),
      );

    const activeDaysLeft = Number(this.maxActiveDays - daysDiff);

    return activeDaysLeft >= 0;
  }
}
