/**
 * Код помилки
 */
export enum ErrorCode {
  /**
   * OK
   */
  Ok = 0,

  /**
   * ПРРО не зареєстрований
   */
  TransactionsRegistrarAbsent = 1,

  /**
   * Відсутній доступ до ПРРО для користувача
   */
  OperatorAccessToTransactionsRegistrarNotGranted = 2,

  /**
   * В документі зазначено реєстраційний код платника,
   * що не дорівнює реєстраційному коду господарської одиниці
   */
  InvalidTin = 3,

  /**
   * Зміну для ПРРО наразі відкрито
   */
  ShiftAlreadyOpened = 4,

  /**
   * Зміну для ПРРО наразі не відкрито
   */
  ShiftNotOpened = 5,

  /**
   * Останній документ, зареєстрований перед закриттям зміни, повинен бути Z-звітом
   */
  LastDocumentMustBeZRep = 6,

  /**
   * Некоректний локальний номер чека
   */
  CheckLocalNumberInvalid = 7,

  /**
   * Z-звіт наразі зареєстрований для поточної зміни
   */
  ZRepAlreadyRegistered = 8,

  /**
   * Помилка валідації документа
   */
  DocumentValidationError = 9,

  /**
   * Помилка валідації пакету офлайн документів
   */
  PackageValidationError = 10,

  /**
   * Некоректний параметр запиту
   */
  InvalidQueryParameter = 11,

  /**
   * Помилка криптографічних функцій
   */
  CryptographyError = 12,
}
