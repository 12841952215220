import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { PresaleComponent } from './presale/presale.component';
import { PresalesRoutingModule } from './presales-routing.module';
import { PresalesComponent } from './presales.component';

@NgModule({
  imports: [SharedModule, PresalesRoutingModule],
  declarations: [PresalesComponent, PresaleComponent],
  providers: [DatePipe],
})
export class PresalesModule {}
