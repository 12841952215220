import { formatNumber, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AlertController, AlertInput, Platform } from '@ionic/angular';
import { finalize } from 'rxjs/operators';

import {
  AUTH_ERROR,
  NEED_PAYMENT,
  ROLE_ERROR,
  SHIFT_CONFLICT as SHIFT_CHECK,
  SHIFT_CONFLICT_WITH_ANOTHER_SHOP,
  SHIFT_WAS_CLOSED,
  SHOP_NOT_SELECTED,
} from './core/constants/error-messages.const';
import { SHOP_REFRESH } from './core/constants/events.const';
import {
  MAX_CURRENCY_SUM_VALUE,
  MIN_CURRENCY_SUM_VALUE,
} from './core/constants/form-validations.const';
import { Config } from './core/constants/version.const';
import { IWorkingIntervals } from './core/interfaces/i-working-intervals';
import { Shift } from './core/models/shift.model';
import { Shop } from './core/models/shop.model';
import { SyncResult } from './core/models/sync-result.model';
import { User } from './core/models/user.model';
import { AuthService } from './core/services/auth.service';
import { CachedDataService } from './core/services/cached-data.service';
import { Events } from './core/services/events.service';
import { LoadingService } from './core/services/loading.service';
import { PreloaderService } from './core/services/preloader.service';
import { ShiftsService } from './core/services/resources/shifts.service';
import { ShopsService } from './core/services/resources/shops.service';
import { TariffService } from './core/services/resources/tariff.service';
import { UserService } from './core/services/resources/user.service';
import { WorkingHoursService } from './core/services/resources/working-hours.service';
import { ToastService } from './core/services/toast.service';
import { UtilsService } from './core/services/utils.service';
import { PRroService } from './p-rro/p-rro.service';
import { PresalesService } from './presales/presales.service';
import { SalesService } from './sales/sales.service';
import { IntegrationService } from './settings/integrations/integration.service';
import { LabelPrinterService } from './settings/printer/label/label-printer.service';
import { ReceiptPrinterService } from './settings/printer/receipt/receipt-printer.service';
import {
  APP_VERSION_WEB_PREFIX,
  CONFIRM_DIALOG_ALERT_STYLE,
  DEFAULT_TOAST_DELAY,
} from './settings/settings.const';
import { SettingService } from './settings/settings.service';
import { TerminalsService } from './settings/terminals/terminals.service';
import { FinanceExpensesService } from './transactions/finance-expanses.service';
import { IncasationsService } from './transactions/incasations.service';
import { Deposit } from './transactions/transaction/models/deposit.model';
import { TransactionsService } from './transactions/transactions.service';

const SHIFT_STATUS_HEADER = 'Статус зміни';

@Component({
  selector: 'bk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  shop: Shop;
  user: User;

  version: string;

  workingTime: number;
  workingTimer: NodeJS.Timeout;

  needSynchronize = false;

  toggleSync = false;
  toggleWarehouse = false;

  isWorkStarted = false;
  isFiscalMode = false;

  private shiftRequestInProgress = false;
  private transactionRequestInProgress = false;
  private syncDataRequestInProgress = false;
  private syncProductsRequestInProgress = false;
  private syncClientsRequestInProgress = false;

  get isShiftOpened(): boolean {
    return this.shiftsService.isOpened();
  }

  get isShiftUser(): boolean {
    return this.shiftsService.isShiftUser();
  }

  constructor(
    private router: Router,
    private platform: Platform,
    private appVersion: AppVersion,
    private statusBar: StatusBar,
    private events: Events,
    private location: Location,
    private loadingService: LoadingService,
    private alertCtrl: AlertController,
    private authService: AuthService,
    private utilsService: UtilsService,
    private shiftsService: ShiftsService,
    private presalesService: PresalesService,
    private salesService: SalesService,
    private incasationsService: IncasationsService,
    private transactionsService: TransactionsService,
    private financeExpensesService: FinanceExpensesService,
    private preloaderService: PreloaderService,
    private tariffService: TariffService,
    private settingService: SettingService,
    private userService: UserService,
    private toastService: ToastService,
    private shopService: ShopsService,
    private cachedDataService: CachedDataService,
    private receiptPrinterService: ReceiptPrinterService,
    private labelPrinterService: LabelPrinterService,
    private workingHoursService: WorkingHoursService,
    private prroService: PRroService,
    private terminalsService: TerminalsService,
    private integrationService: IntegrationService,
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.overlaysWebView(false);
        this.appVersion
          .getVersionNumber()
          .then((version) => {
            this.version = version;
          })
          .catch(() => {
            this.version = '0.0.0';

            this.toastService.present(
              'Помилка Cordova-плагіну',
              'Не вдалося визначити версію додатку',
            );
          });
      } else {
        this.version = `${APP_VERSION_WEB_PREFIX}:${Config.VERSION}`;
      }
    });

    this.checkDarkTheme();

    this.events.subscribe('auth:error', () => {
      this.router.navigate(['/login'], { replaceUrl: true });
    });

    this.events.subscribe('initialize-work-timer', (confirmed: boolean) => {
      if (confirmed) {
        this.initializeCurrentInterval();
      }
    });

    this.events.subscribe(
      'restart-work-timer',
      (checkLocalStorage: boolean) => {
        this.startWorkingInterval(checkLocalStorage);
      },
    );

    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });

    this.events.subscribe('fiscal:status', (status: boolean) => {
      this.isFiscalMode = status;
    });

    this.events.subscribe(SHOP_REFRESH, (shop: Shop) => {
      this.shop = shop;
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.isAuthenticated()) {
      const role = this.authService.getUserRole().toUpperCase();
      const appSettings = this.cachedDataService.getAppSettings();

      if (role !== 'EMPLOYEE' && role !== 'SUPER_ADMIN') {
        this.toastService.present(
          'Доступ заборонено',
          `${ROLE_ERROR} ${appSettings.url}`,
        );

        this.unsafeLogout();

        return;
      }

      if (this.cachedDataService.getShopId() == null) {
        const alert = await this.alertCtrl.create({
          header: AUTH_ERROR,
          message: SHOP_NOT_SELECTED,
          buttons: [
            {
              text: 'Закрити',
              role: 'close',
              handler: () => {
                this.unsafeLogout();
              },
            },
          ],
        });

        await alert.present();

        return;
      }

      if (await this.utilsService.isOnline()) {
        this.needSynchronize = this.salesService.haveUnsyncedSales();

        this.settingService.checkApiVersion();
        this.userService.init();
        this.shopService.getShop().subscribe((shop) => {
          this.initServices(shop);
          this.checkTariff();
        });
      } else {
        this.userService.initLocal();
        this.shopService.getShopLocal().subscribe((shop) => {
          this.initServices(shop);
        });

        this.shiftsService.getShiftLocal().subscribe((shift) => {
          this.checkShift(shift);
        });

        this.goToShop();
        this.toastService.presentNoInternet();
      }
    } else {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  private initServices(shop: Shop): void {
    this.shop = shop;
    this.isFiscalMode = this.cachedDataService.isFiscalMode();

    this.receiptPrinterService.init();
    this.labelPrinterService.init();
    this.integrationService.init();
  }

  async navigateTo(route: string): Promise<void> {
    await this.syncData({ silentMode: true });

    this.router.navigateByUrl(route);
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  formatTime(seconds: number): string {
    let h = Math.floor(seconds / 3600).toString();
    h = h.length === 1 ? `0${h}` : h;

    let m = Math.floor((seconds % 3600) / 60).toString();
    m = m.length === 1 ? `0${m}` : m;

    let s = Math.floor((seconds % 3600) % 60).toString();
    s = s.length === 1 ? `0${s}` : s;

    return `${h}:${m}:${s}`;
  }

  async openShift(isConnectToShift: boolean = false): Promise<void> {
    if (!(await this.utilsService.isOnline())) {
      this.toastService.presentNoInternet();
      return;
    }

    if (this.shiftRequestInProgress) {
      return;
    }

    this.shiftRequestInProgress = true;

    const loadingId = 'app.component:openShift';

    await this.loadingService.presentCustomPreloader(loadingId);

    this.shiftsService
      .open()
      .pipe(finalize(() => this.finishShiftOperation(loadingId)))
      .subscribe(
        async (shift) => {
          isConnectToShift = shift.isConnected ?? isConnectToShift;

          const prroReport = await this.openFiscalShift();

          if (!this.isWorkStarted) {
            this.toggleWorkingInterval();
          }

          const shiftMessage = isConnectToShift
            ? 'До робочої зміни приєднано'
            : 'Робочу зміну відкрито';

          if (isConnectToShift) {
            if (prroReport > '') {
              const prroAlert = await this.alertCtrl.create({
                header: SHIFT_STATUS_HEADER,
                message: `${shiftMessage}<br>${prroReport}`,
                buttons: [{ text: 'Закрити', role: 'close' }],
              });

              await prroAlert.present();
            } else {
              this.toastService.present(shiftMessage);
            }
          } else {
            await this.createCashInputDialog(
              shift,
              `${shiftMessage}<br>${prroReport}`,
            );
          }

          if (shift.isNeedSync) {
            await this.syncProducts();
          }
        },
        (error) => {
          this.processShiftError(error, 'відкрити зміну');
        },
      );
  }

  private async openFiscalShift(): Promise<string> {
    if (this.cachedDataService.getPRRO()?.syncShiftOpening) {
      const { prroReport } = await this.prroService.openShift();

      return prroReport;
    }

    return '';
  }

  private finishShiftOperation(loadingId: string): void {
    this.loadingService.dismiss(loadingId);
    this.shiftRequestInProgress = false;
  }

  private async createCashInputDialog(
    shift: Shift,
    statusMessage: string,
  ): Promise<void> {
    const inputFieldId = 'inputField';

    const alert = await this.alertCtrl.create({
      header: SHIFT_STATUS_HEADER,
      message: `
        ${statusMessage}${statusMessage.endsWith('<br>') ? '<br>' : '<br><br>'}
        Сума внесення в касу, ₴`,
      inputs: [
        {
          id: inputFieldId,
          name: 'inputAmount',
          placeholder: `Сума внесення, ₴`,
          type: 'number',
          value: shift.lastShiftCashAmount ?? 0,
          min: MIN_CURRENCY_SUM_VALUE,
          max: MAX_CURRENCY_SUM_VALUE,
        },
      ],
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Підтвердити',
          role: 'confirm',
          cssClass: 'primary',
          handler: (data: { inputAmount: string }) => {
            this.createCashInput(data.inputAmount);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
      backdropDismiss: false,
    });

    alert.addEventListener('ionAlertDidPresent', (e) => {
      const inputField = document.getElementById(inputFieldId);

      if (!inputField) {
        return;
      }

      setTimeout(() => {
        inputField.focus();

        if (inputField instanceof HTMLInputElement) {
          inputField.select();
        }
      }, 100);

      inputField.onkeyup = async (event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (inputField instanceof HTMLInputElement) {
          this.createCashInput(inputField.value);

          await alert.dismiss();
        }
      };
    });

    await alert.present();
  }

  private async createCashInput(value: string): Promise<void> {
    if (!value) {
      return;
    }

    const inputAmount = Math.max(0, Math.abs(Number(value)));

    if (inputAmount > MAX_CURRENCY_SUM_VALUE || inputAmount === 0) {
      this.createCashInputToast(inputAmount);

      return;
    }
    if (this.transactionRequestInProgress) {
      return;
    }

    this.transactionRequestInProgress = true;

    await this.transactionsService.create(
      new Deposit({
        amount: inputAmount,
        comment: 'Внесення коштів на початок робочої зміни',
      }),
    );

    this.transactionRequestInProgress = false;
  }

  private createCashInputToast(inputAmount: number): void {
    const recommendation =
      'За необхідності, повторіть спробу вручну:\nМеню (☰) - Каса - вкладка "ВНЕСЕННЯ КОШТІВ"';

    this.toastService.presentWarning(
      'Внесення коштів в касу',
      `Сума внесення має бути ${
        inputAmount > MAX_CURRENCY_SUM_VALUE ? 'меншою' : 'більшою'
      } за ${formatNumber(
        inputAmount > MAX_CURRENCY_SUM_VALUE ? MAX_CURRENCY_SUM_VALUE : 0,
        'uk_UA',
        '1.2-2',
      )} ₴, поточне значення - ${formatNumber(
        inputAmount,
        'uk_UA',
        '1.2-2',
      )} ₴\n${recommendation}`,
      7500,
    );
  }

  async closeShift(): Promise<void> {
    const isOnline = await this.utilsService.isOnline();

    if (!isOnline) {
      this.toastService.presentNoInternet();

      return;
    }

    if (this.salesService.haveUnsyncedSales()) {
      this.showUnsyncedError();

      return;
    }

    const alertInputs: AlertInput[] = [];
    const printZReport = 'printZReport';
    const printShiftTotals = 'printShiftTotals';

    const receiptPrinterStatus = await this.receiptPrinterService.status();

    if (receiptPrinterStatus.isPrinterAvailable && this.user.showCash) {
      if (this.cachedDataService.isPRroActive()) {
        alertInputs.push({
          name: printZReport,
          type: 'checkbox',
          label: 'Друкувати Z-звіт',
          value: printZReport,
          checked: false,
        });
      }

      alertInputs.push({
        name: printShiftTotals,
        type: 'checkbox',
        label: 'Друкувати звіт за зміну',
        value: printShiftTotals,
        checked: false,
      });
    }

    const alert = await this.alertCtrl.create({
      header: SHIFT_STATUS_HEADER,
      message: `
        Робоча зміна буде закрита<br><br> 
        <strong>Закрити зміну</strong>?`,
      inputs: alertInputs,
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Закрити зміну',
          role: 'confirm',
          cssClass: 'primary',
          handler: (options: string[]) => {
            this.closeShiftConfirmed(
              options?.includes(printZReport),
              options?.includes(printShiftTotals),
            );
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    await alert.present();
  }

  async closeShiftConfirmed(
    printZReport: boolean = false,
    printShiftTotals: boolean = false,
  ): Promise<void> {
    if (this.shiftRequestInProgress) {
      return;
    }

    this.shiftRequestInProgress = true;

    const loadingId = 'app.component:closeShiftConfirmed';

    await this.loadingService.presentCustomPreloader(loadingId);

    const {
      operationReport: prroReport,
      zReport,
      canLogout,
    } = await this.prroService.createZReportAndCloseShift();

    if (zReport != null && printZReport) {
      setTimeout(async () => {
        await this.receiptPrinterService.printTaxReport(zReport, {
          viewMode: false,
        });
      }, 500);
    }

    const terminalNote = await this.terminalsService.closeBatch();

    this.shiftsService
      .close()
      .pipe(finalize(() => this.finishShiftOperation(loadingId)))
      .subscribe(
        async (shift) => {
          if (printShiftTotals) {
            this.receiptPrinterService.printShiftTotals(shift);
          }

          this.finishWorkingInterval();

          if (prroReport > '') {
            const alert = await this.alertCtrl.create({
              header: SHIFT_STATUS_HEADER,
              message: `Робочу зміну закрито<br>
                ${terminalNote}
                ${prroReport}`,
              buttons: [
                {
                  text: 'Закрити',
                  role: 'close',
                  handler: () => {
                    if (canLogout) {
                      this.logout();
                    }
                  },
                },
              ],
              backdropDismiss: false,
            });

            await alert.present();
          } else {
            this.toastService.present(
              `Робочу зміну закрито`,
              terminalNote.replace('<br>', ''),
            );

            setTimeout(() => {
              this.logout();
            }, DEFAULT_TOAST_DELAY);
          }
        },
        (error) => {
          this.processShiftError(error, 'закрити зміну');
        },
      );
  }

  async syncData(
    options: { silentMode: boolean } = { silentMode: false },
  ): Promise<void> {
    const isOnline = await this.utilsService.isOnline();

    if (!isOnline) {
      this.toastService.presentNoInternet();
      return;
    }

    if (this.syncDataRequestInProgress) {
      return;
    }

    this.syncDataRequestInProgress = true;

    const loading_name = 'app.component:syncData';

    if (!options.silentMode) {
      await this.loadingService.presentCustomPreloader(
        loading_name,
        'Синхронізація...',
      );
    }

    this.userService.init();

    const syncResults: SyncResult[] = [];

    if (this.shop.presales) {
      const syncPresales = await this.presalesService.sync();

      syncResults.push(syncPresales);
    }

    const syncSales = await this.salesService.sync();
    const syncIncasations = await this.incasationsService.sync();
    const syncFinanceExpenses = await this.financeExpensesService.sync();

    syncResults.push(syncSales);
    syncResults.push(syncIncasations);
    syncResults.push(syncFinanceExpenses);

    this.syncDataRequestInProgress = false;

    if (!options.silentMode) {
      this.loadingService.dismiss(loading_name);
    }

    let successMessages = '';
    let problemMessages = '';

    for (const syncResult of syncResults) {
      if (syncResult.success && !syncResult.warning) {
        successMessages += `${syncResult.message}\n`;
      } else {
        problemMessages += `${syncResult.message}<br><br>`;
      }
    }

    this.needSynchronize = Boolean(problemMessages);

    if (options.silentMode) {
      return;
    }

    const notificationHeader = `Результати синхронізації`;

    if (problemMessages > '') {
      const alert = await this.alertCtrl.create({
        header: notificationHeader,
        message: successMessages
          ? `${successMessages.replace(
              new RegExp('\n', 'g'),
              '<br><br>',
            )}${problemMessages}`
          : `${problemMessages}`,
        buttons: [
          { text: 'Закрити', role: 'close' },
          {
            text: 'Повторити',
            handler: () => {
              this.syncData();
            },
          },
        ],
      });

      await alert.present();
    } else {
      this.toastService.present(notificationHeader, successMessages, 5000);
    }
  }

  async syncProducts(): Promise<void> {
    const isOnline = await this.utilsService.isOnline();

    if (!isOnline) {
      this.toastService.presentNoInternet();
      return;
    }

    if (this.syncProductsRequestInProgress) {
      return;
    }

    this.syncProductsRequestInProgress = true;

    await this.preloaderService.syncProducts();

    this.syncProductsRequestInProgress = false;
  }

  async syncClients(): Promise<void> {
    const isOnline = await this.utilsService.isOnline();

    if (!isOnline) {
      this.toastService.presentNoInternet();
      return;
    }

    if (this.syncClientsRequestInProgress) {
      return;
    }

    this.syncClientsRequestInProgress = true;

    await this.preloaderService.syncClients();

    this.syncClientsRequestInProgress = false;
  }

  async logout(): Promise<void> {
    if (!(await this.utilsService.isOnline())) {
      this.toastService.presentNoInternet();

      return;
    }

    if (this.salesService.haveUnsyncedSales()) {
      this.showUnsyncedError();

      return;
    }

    this.authService.removeToken();
  }

  async toggleWorkingInterval(): Promise<void> {
    if (!(await this.utilsService.isOnline())) {
      this.toastService.presentNoInternet();

      return;
    }

    if (this.isWorkStarted) {
      this.workingHoursService.stopWorkingHours(this.user.id).subscribe(() => {
        this.finishWorkingInterval();
      });
    } else {
      this.startWorkingInterval();
    }
  }

  private startWorkingInterval(checkLocalStorage: boolean = false): void {
    if (!this.isShiftUser) {
      return;
    }

    this.workingHoursService
      .startWorkingHours(checkLocalStorage)
      .subscribe((data: IWorkingIntervals) => {
        if (data) {
          this.isWorkStarted = true;

          this.initializeWorkingInterval(data);
        }
      });
  }

  private finishWorkingInterval(): void {
    this.isWorkStarted = false;

    clearInterval(this.workingTimer);
  }

  private async showUnsyncedError(): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Підказка',
      message:
        'Знайдено несинхронізовані дані продажів чи касових операцій.<br> Будь ласка, виконайте синхронізацію',
      buttons: [{ text: 'Закрити', role: 'close' }],
    });

    await alert.present();
  }

  private checkTariff(): void {
    this.tariffService.canActivatePage().then((tariffPaid) => {
      if (tariffPaid) {
        this.shiftsService.getShift().subscribe((shift) => {
          if (!this.checkShift(shift)) {
            return;
          }
        });

        this.goToShop();
      } else {
        this.alertCtrl
          .create({
            header: 'Адміністративна помилка',
            message: NEED_PAYMENT,
            buttons: [{ text: 'Закрити', role: 'close' }],
          })
          .then((alert) => alert.present());

        this.router.navigate(['/login'], { replaceUrl: true });
      }
    });
  }

  private goToShop(): void {
    if (!this.location.isCurrentPathEqualTo('/client-screen')) {
      this.router.navigate(['/shop'], { replaceUrl: true });
    }
  }

  private checkShift(shift: Shift | null): boolean {
    if (!shift) {
      return false;
    }

    const shopId = this.cachedDataService.getShopId();

    if (shift.closed) {
      this.processShiftError(SHIFT_WAS_CLOSED, '');

      return false;
    }

    if (shift.shopId !== shopId) {
      this.processShiftError(SHIFT_CONFLICT_WITH_ANOTHER_SHOP, '');

      return false;
    }

    if (!shift.closed) {
      this.initializeCurrentInterval();
    }

    return true;
  }

  private processShiftError(error: any, operationName: string): void {
    if (typeof error === 'string') {
      this.toastService.present(SHIFT_CHECK, error);

      setTimeout(() => {
        this.unsafeLogout();
      }, 5000);
    } else {
      const httpError = this.utilsService.getParsedError(error);
      const note = `Не вдалося ${operationName}`;

      if (httpError.statusCode) {
        this.utilsService.showErrorAlert(httpError);
      } else {
        this.utilsService.showErrorToast(httpError, {
          note,
          isWarning: true,
        });
      }
    }
  }

  private unsafeLogout(): void {
    this.authService.removeToken();
  }

  private async initializeCurrentInterval(): Promise<void> {
    if (!this.isShiftUser) {
      return;
    }

    if (await this.utilsService.isOnline()) {
      this.workingHoursService
        .getCurrentWorkingHours()
        .subscribe((time: IWorkingIntervals) => {
          if (time && time.id) {
            this.isWorkStarted = true;

            this.initializeWorkingInterval(time);
          } else {
            this.isWorkStarted = false;
            this.workingTime = time && time.time > 0 ? time.time : 0;
          }
        });
    } else {
      this.workingHoursService
        .getCurrentWorkingHoursLocal()
        .subscribe((time: IWorkingIntervals) => {
          this.isWorkStarted = true;

          this.initializeWorkingInterval(time);
        });
    }
  }

  private initializeWorkingInterval(data: IWorkingIntervals): void {
    if (this.workingTimer) {
      clearInterval(this.workingTimer);
    }

    this.workingTime = data.time;
    this.workingTimer = setInterval(() => {
      this.workingTime += 1;
    }, 1000);
  }

  private checkDarkTheme(): void {
    const darkThemeMode =
      this.cachedDataService.getUserSettings().darkThemeMode;

    if (darkThemeMode) {
      document.body.classList.toggle('dark', darkThemeMode);
    }
  }
}
