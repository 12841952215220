import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ToastService } from '../../core/services/toast.service';

@Component({
  selector: 'bk-second-screen',
  templateUrl: './second-screen.component.html',
  styleUrls: ['./second-screen.component.scss'],
})
export class SecondScreenComponent implements OnInit {
  TAB_ESB_WINDOW_POSITIONER = {
    name: 'ESBWindowPositioner',
    title: 'ESB Window Positioner',
  };

  TAB_MULTIWINDOW_POSITIONER = {
    name: 'MultiWindowPositioner',
    title: 'MultiWindow Positioner',
  };

  activePlugin = this.TAB_ESB_WINDOW_POSITIONER;

  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.activePlugin = this.TAB_ESB_WINDOW_POSITIONER;
  }

  changedTab(event: any): void {
    this.activePlugin = event.detail.value;
  }

  isMultiWindowPositioner(): boolean {
    return this.activePlugin.name === this.TAB_MULTIWINDOW_POSITIONER.name;
  }

  installPlugin(): void {
    window.open(
      `https://chromewebstore.google.com/search/${this.activePlugin.title
        .split(' ')
        .join('-')}`,
      '_blank',
    );
  }

  openSettings(): void {
    window.open(
      `chrome-extension://hmgehpjpfhobbnhhelhlggjfcaollidl/options.html`,
      '_blank',
    );
  }

  copyToClipboard(): void {
    if (this.activePlugin.name === this.TAB_ESB_WINDOW_POSITIONER.name) {
      this.clipboard.copy(
        `${window.location.href.split('#')[0]}#/client-screen`,
      );

      this.toastService.present(
        'Посилання на екран клієнта скопійовано у буфер обміну',
      );
    } else if (
      this.activePlugin.name === this.TAB_MULTIWINDOW_POSITIONER.name
    ) {
      this.clipboard.copy(
        `${environment.apiUrl}/settings/chrome-multiwindow-positioner-templates`,
      );

      this.toastService.present(
        'Посилання на шаблон налаштувань скопійовано у буфер обміну',
      );
    }
  }
}
