<ion-header>
  <ion-toolbar class="ion-text-center">
    <ion-title>{{ saleProducts.length && !summary && !isThanks ? 'Перелік товарів' : PRRO_NAME }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="dark-background">
  <img-loader class="image-background" 
              backgroundSize="cover" 
              [src]="slidePath" 
              [@fade]="state" 
              (@fade.done)="onDone($event)"></img-loader>

  <section class="data" *ngIf="saleProducts.length && !summary && !isThanks">
    <div class="table-header">
      <div class="column-name">Товар</div>
      <div class="column-quantity">К-ть</div>
      <div class="column-price">Ціна, ₴</div>
      <div class="column-sum">Сума, ₴</div>
    </div>

    <ion-content>
      <ng-container *ngIf="saleProducts.length; else noData">
        <div class="even-row" *ngFor="let saleProduct of saleProducts">
          <div class="table-data-row">
            <div class="column-name">{{ saleProduct.product.name }}, {{ saleProduct.product.amount }}</div>
            <div class="column-quantity">{{ getProductQuantity(saleProduct) }}</div>
            <div class="column-price">{{ saleProduct.price | number: '1.2-2' }}</div>
            <div class="column-sum">{{ saleProduct.cost | number: '1.2-2' }}</div>
          </div>
        </div>
      </ng-container>

      <ng-template #noData>
        <div class="no-data">
          <span>Немає товарів</span>
        </div>
      </ng-template>
    </ion-content>
  </section>

  <section class="data-narrow" *ngIf="summary && !isThanks">
    <div class="summary">
      <ion-grid>
        <ion-row *ngIf="summary.totalDiscount || summary.totalRound">
          <ion-col size="6">Всього</ion-col>
          <ion-col size="6">{{ summary.totalSum | currency:'UAH':'symbol-narrow' }}</ion-col>
        </ion-row>
  
        <ion-row *ngIf="summary.totalDiscount">
          <ion-col size="6">Знижка</ion-col>
          <ion-col size="6">{{ summary.totalDiscount | currency:'UAH':'symbol-narrow' }}</ion-col>
        </ion-row>
  
        <ion-row *ngIf="summary.totalRound">
          <ion-col size="6">Заокруглення</ion-col>
          <ion-col size="6">{{ summary.totalRound | currency:'UAH':'symbol-narrow' }}</ion-col>
        </ion-row>
  
        <ion-row class="bottom-border bold">
          <ion-col size="6">До оплати</ion-col>
          <ion-col size="6">{{ summary.totalPayment | currency:'UAH':'symbol-narrow' }}</ion-col>
        </ion-row>
  
        <ion-row *ngIf="summary.totalCard">
          <ion-col size="6">Картка</ion-col>
          <ion-col size="6">{{ summary.totalCard | currency:'UAH':'symbol-narrow' }}</ion-col>
        </ion-row>
  
        <ion-row *ngIf="summary.totalCash">
          <ion-col size="6">Готівка</ion-col>
          <ion-col size="6">{{ summary.totalCash | currency:'UAH':'symbol-narrow' }}</ion-col>
        </ion-row>
  
        <ion-row *ngIf="summary.clientCash">
          <ion-col size="6">Отримано</ion-col>
          <ion-col size="6">{{ summary.clientCash | currency:'UAH':'symbol-narrow' }}</ion-col>
        </ion-row>
  
        <ion-row class="top-border bold" *ngIf="summary.totalChange">
          <ion-col size="6">Решта</ion-col>
          <ion-col size="6">{{ summary.totalChange | currency:'UAH':'symbol-narrow' }}</ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </section>

  <section class="data" *ngIf="isThanks">
    <div class="thanks">
      <div class="thanks__logo">
        <img [src]="shop.logoUrl" *ngIf="shop.logoUrl">
      </div>
  
      <div class="thanks__default">
        <span>Дякуємо за покупку!</span>
      </div>

      <div class="thanks__comment">
        <span *ngIf="shop.checkComment">{{ shop.checkComment }}</span>
      </div>  
    </div>
  </section>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-text color="light">
      <div class="products" *ngIf="saleProducts.length && !summary && !isThanks">
        <div class="products__quantity">{{ saleProducts.length ? saleProducts.length : '0' }} од.</div>
        <div class="products__amount">Всього: {{ totals.amount | number: '1.2-2' }} ₴</div>
      </div>
    </ion-text>
  </ion-toolbar>
</ion-footer>
