import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ImageLoaderService } from 'ionic-image-loader-v5';
import { forkJoin, of, Observable } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ClientsService } from '../../clients/clients.service';
import { CategoriesService } from '../../shop/categories/categories.service';
import { Category } from '../../shop/categories/category.model';
import { ProductsService } from '../../shop/products/products.service';
import { SubcategoriesService } from '../../shop/subcategories/subcategories.service';
import { Subcategory } from '../../shop/subcategories/subcategory.model';
import { SYNC_PRODUCTS_SUCCESS } from '../constants/events.const';

import { Events } from './events.service';
import { LoadingService } from './loading.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  constructor(
    private loadingService: LoadingService,
    private toastService: ToastService,
    private events: Events,
    private alertCtrl: AlertController,
    private categoriesService: CategoriesService,
    private productsService: ProductsService,
    private subcategoriesService: SubcategoriesService,
    private clientsService: ClientsService,
    protected imageLoader: ImageLoaderService,
  ) {}

  async syncClients(): Promise<void> {
    await this.loadingService.presentCustomPreloader(
      'PreloaderService:syncClients',
    );

    this.preloadClients()
      .pipe(
        finalize(() => {
          this.loadingService.dismiss('PreloaderService:syncClients');
        }),
      )
      .subscribe(
        () => {
          this.toastService.present('Перелік клієнтів синхронізовано');
          this.events.publish(SYNC_PRODUCTS_SUCCESS);
        },
        () => {
          this.alertCtrl
            .create({
              header: 'Помилка синхронізації',
              message:
                'Не вдалося виконати синхронізацію клієнтів:(<br><br>Будь ласка, спробуйте ще раз!',
              buttons: [
                { text: 'Закрити', role: 'close' },
                {
                  text: 'Повторити',
                  handler: () => {
                    this.syncClients();
                  },
                },
              ],
            })
            .then((alert) => alert.present());
        },
      );
  }

  async syncProducts(): Promise<void> {
    await this.loadingService.presentCustomPreloader(
      'PreloaderService:syncProducts',
    );

    this.preload()
      .pipe(
        finalize(() => {
          this.loadingService.dismiss('PreloaderService:syncProducts');
        }),
      )
      .subscribe(
        () => {
          this.toastService.present('Перелік товарів та послуг синхронізовано');
          this.events.publish(SYNC_PRODUCTS_SUCCESS);
        },
        () => {
          this.alertCtrl
            .create({
              header: 'Помилка синхронізації',
              message:
                'Не вдалося виконати синхронізацію товарів та послуг:(<br><br>Будь ласка, спробуйте ще раз!',
              buttons: [
                { text: 'Закрити', role: 'close' },
                {
                  text: 'Повторити',
                  handler: () => {
                    this.syncProducts();
                  },
                },
              ],
            })
            .then((alert) => alert.present());
        },
      );
  }

  preloadClients(): Observable<any> {
    // Preload categories and all products
    return this.clientsService.findForApp({}, { forceRefresh: true });
  }

  preload(): Observable<any> {
    this.imageLoader.clearCache();

    // Preload categories and all products
    return this.categoriesService.findForApp({}, { forceRefresh: true }).pipe(
      switchMap((categories) => this.preloadSubcategories(categories)),
      switchMap(() => this.preloadAllProducts()),
    );
  }

  private preloadSubcategories(categories: Category[]): Observable<any> {
    if (categories.length === 0) {
      return of(null);
    }

    return forkJoin(
      categories.map((category) => {
        if (category.coverPath) {
          this.imageLoader.preload(
            `${environment.apiUrl}/categories/${category.id}/cover/${category.coverPath}`,
          );
        }

        return this.subcategoriesService
          .findForApp({ categoryId: category.id }, { forceRefresh: true })
          .pipe(
            switchMap((subcategories) => this.preloadProducts(subcategories)),
          );
      }),
    );
  }

  private preloadProducts(subcategories: Subcategory[]): Observable<any> {
    if (subcategories.length === 0) {
      return of(null);
    }

    return forkJoin(
      subcategories.map((subcategory) => {
        if (subcategory.coverPath) {
          this.imageLoader.preload(
            `${environment.apiUrl}/subcategories/${subcategory.id}/cover/${subcategory.coverPath}`,
          );
        }

        return this.productsService
          .findForApp({ subcategoryId: subcategory.id }, { forceRefresh: true })
          .pipe(
            tap((products) => {
              products.forEach((product) => {
                if (product.coverPath) {
                  this.imageLoader.preload(
                    `${environment.apiUrl}/products/${product.id}/cover/${product.coverPath}`,
                  );
                }
              });
            }),
          );
      }),
    );
  }

  private preloadAllProducts(): Observable<any> {
    return this.productsService.findForApp(
      {},
      { forceRefresh: true, useIndexedDb: true },
    );
  }
}
