import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';

import {
  BROADCAST_PING,
  BROADCAST_PONG,
  BROADCAST_SALE_INVOICE,
} from '../core/constants/events.const';
import { Shop } from '../core/models/shop.model';
import { BroadcastService } from '../core/services/broadcast.service';
import { SaleProduct } from '../sales/sale/sale-product.model';

@Injectable({
  providedIn: 'root',
})
export class ClientScreenService {
  private isClientScreen = false;

  constructor() {}

  open(
    shop: Shop,
    saleProducts: SaleProduct[],
    broadcastService?: BroadcastService,
  ): void {
    if (broadcastService == null || !shop.clientScreen) {
      return;
    }

    broadcastService.publish({ type: BROADCAST_PING });

    const pongMessage = broadcastService
      .messagesOfType(BROADCAST_PONG)
      .pipe(timeout(2500))
      .subscribe(
        (data) => {
          this.isClientScreen = true;

          this.update(shop, saleProducts, broadcastService);

          pongMessage.unsubscribe();
        },
        (error) => {
          const clientScreen = window.open('/#/client-screen', '_blank');

          if (clientScreen != null) {
            clientScreen.onload = () => {
              this.isClientScreen = true;

              this.update(shop, saleProducts, broadcastService);
            };
          } else {
            this.isClientScreen = false;
          }
        },
      );
  }

  update(
    shop: Shop,
    saleProducts: SaleProduct[],
    broadcastService?: BroadcastService,
  ): void {
    if (broadcastService == null || !shop.clientScreen) {
      return;
    }

    if (!this.isClientScreen) {
      this.open(shop, saleProducts, broadcastService);

      return;
    }

    if (this.isClientScreen) {
      broadcastService.publish({
        type: BROADCAST_SALE_INVOICE,
        payload: saleProducts,
      });
    }
  }
}
