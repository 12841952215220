import { Location } from '@angular/common';
import {
  Component,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import {
  ActionSheetButton,
  ActionSheetController,
  AlertController,
  IonContent,
  IonSearchbar,
  IonSlides,
  ModalController,
  Platform,
} from '@ionic/angular';
import pull from 'lodash-es/pull';
import remove from 'lodash-es/remove';
import round from 'lodash-es/round';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';

import { ClientScreenService } from '../client-screen/client-screen.service';
import { BROADCAST_SERVICE } from '../core/constants/broadcast-token.const';
import {
  BARCODE_CHECK,
  BROADCAST_SALE_SOLD,
  BROADCAST_TAB_CLOSED,
  BROADCAST_TAB_CREATED,
  BROADCAST_TAB_EXIST,
  INVOICE_ADD_PRODUCT,
  INVOICE_ADD_WEIGHT_PRODUCT,
  INVOICE_AUTO_SALE,
  PRRO_STATE,
  SETTINGS_BLUETOOTH_BARCODE_SCANNER,
  SETTINGS_ONLINE_CHECK,
  SYNC_PRODUCTS_SUCCESS,
} from '../core/constants/events.const';
import {
  BARCODE_EAN_13,
  BARCODE_EAN_8,
  MAX_QUANTITY_VALUE,
  ONE_KOP,
} from '../core/constants/form-validations.const';
import { SALE_CALCULATION_DIALOG_ID } from '../core/constants/modal-controller.const';
import {
  WEIGHT_AMOUNT,
  WEIGHT_BARCODE_PREFIX,
} from '../core/constants/product.const';
import {
  SEARCH_COMMENT,
  SEARCH_RECORDS,
  SEARCH_ROWS,
} from '../core/constants/search.const';
import { AutoSaleOptions } from '../core/interfaces/auto-sale-options.interface';
import { IStorageUserSettings } from '../core/interfaces/storage-user-setting.interface';
import { Shop } from '../core/models/shop.model';
import { User } from '../core/models/user.model';
import { AuthService } from '../core/services/auth.service';
import { BroadcastService } from '../core/services/broadcast.service';
import { CachedDataService } from '../core/services/cached-data.service';
import { Events } from '../core/services/events.service';
import { HostListenerService } from '../core/services/host-listener.service';
import { ShiftsService } from '../core/services/resources/shifts.service';
import { WorkingHoursService } from '../core/services/resources/working-hours.service';
import { ToastService } from '../core/services/toast.service';
import { UtilsService } from '../core/services/utils.service';
import { CheckDocumentSubType } from '../p-rro/fsco/enums/check-document-sub-type.enum';
import { PayFormCode } from '../p-rro/fsco/enums/pay-form-code.enum';
import { ResponsePRROState } from '../p-rro/fsco/interfaces/responses/response-prro-state.interface';
import { PRroService } from '../p-rro/p-rro.service';
import { SalePayment } from '../sales/sale/sale-payment.model';
import { SaleProduct } from '../sales/sale/sale-product.model';
import { Sale } from '../sales/sale/sale.model';
import { SalesService } from '../sales/sales.service';
import { Integration } from '../settings/integrations/integration.enum';
import { IntegrationService } from '../settings/integrations/integration.service';
import { ScalesService } from '../settings/scales/scales.service';
import {
  CONFIRM_DIALOG_ALERT_STYLE,
  KEYBOARD_MODE_DEFAULT_VALUE,
} from '../settings/settings.const';
import { TerminalsService } from '../settings/terminals/terminals.service';
import { InvoiceProductsTotals } from '../shared/components/invoice-products/invoice-products.interface';
import { InvoiceProductsService } from '../shared/components/invoice-products/invoice-products.service';
import { MultibarcodeDialog } from '../shared/components/multibarcode-dialog/multibarcode.dialog';

import { ExciseProductDialog } from './excise-product/excise-product.dialog';
import { LandscapeMenuComponent } from './landscape-menu/landscape-menu.component';
import { ProductPriceDialog } from './product-price/product-price.dialog';
import { Product } from './products/product.model';
import { ProductsService } from './products/products.service';
import { SaleCalculationDialog } from './sale-calculation/sale-calculation.dialog';
import { WeightProductComponent } from './weight-product/weight-product';

@Component({
  selector: 'bk-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit, OnDestroy {
  @ViewChild(IonSearchbar) searchbar: IonSearchbar;
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  @ViewChild(LandscapeMenuComponent) landscapeMenu: LandscapeMenuComponent;
  @ViewChild('productList') productList: IonContent;

  slideIndex = 0;

  invoices: SaleProduct[][] = [];

  shop: Shop;
  user: User;
  userSettings: IStorageUserSettings;

  private integrationTimer: NodeJS.Timeout;

  isOnline = true;
  isLandscapeMode = false;
  isDesktop = false;
  isFiscalMode = false;

  private dialogInProgress = false;

  requestInProgress = false;
  requestCashInProgress = false;
  requestCardInProgress = false;

  searchBarcodeMode = false;
  searchBarcode = '';

  searchProductMode = false;
  searchProduct = '';

  filteredProducts: Product[] = [];

  private products: Product[] = [];
  private isChoseProduct = false;

  SEARCH_RECORDS = SEARCH_RECORDS;
  SEARCH_COMMENT = SEARCH_COMMENT;
  SEARCH_ROWS = SEARCH_ROWS;

  private hostBarcode = '';
  private eventType: string;

  private isPrroStateError = false;

  prroState?: ResponsePRROState;
  broadcastService?: BroadcastService;

  private subscription = new Subscription();

  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (/^[0-9]$/.test(event.key)) {
      this.hostBarcode = `${this.hostBarcode}${event.key}`;
    }

    if (event.key === 'Enter') {
      if (
        this.hostBarcode !== '' &&
        this.hostBarcode.length >= BARCODE_EAN_8 &&
        this.hostBarcode.length <= BARCODE_EAN_13
      ) {
        this.events.publish(`Barcode-${this.eventType}`, this.hostBarcode);
        this.hostBarcode = '';
      } else {
        this.hostBarcode = '';
      }
    }
  }

  //#region get
  get totals(): InvoiceProductsTotals {
    return this.invoiceProductsService.getTotals(
      this.invoices[this.getSlideIndex()],
    );
  }

  get isShiftOpened(): boolean {
    return this.shiftsService.isOpened();
  }

  get isShiftUser(): boolean {
    return this.shiftsService.isShiftUser();
  }

  get isWorkingTimeStarted(): boolean {
    return this.workingHoursService.isStarted();
  }

  //#region constructor
  constructor(
    private injector: Injector,
    private router: Router,
    private events: Events,
    private platform: Platform,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private actionSheetController: ActionSheetController,
    private shiftsService: ShiftsService,
    private productsService: ProductsService,
    private toastService: ToastService,
    private utilsService: UtilsService,
    private screenOrientation: ScreenOrientation,
    private workingHoursService: WorkingHoursService,
    private salesService: SalesService,
    private location: Location,
    private cachedDataService: CachedDataService,
    private prroService: PRroService,
    private hostListenerService: HostListenerService,
    private authService: AuthService,
    private scalesService: ScalesService,
    private terminalsService: TerminalsService,
    private integrationService: IntegrationService,
    private clientScreenService: ClientScreenService,
    private invoiceProductsService: InvoiceProductsService,
  ) {
    if (this.cachedDataService.getShopId() == null) {
      this.authService.removeToken();
      this.router.navigate(['/login'], { replaceUrl: true });
      return;
    }

    this.cordovaOptions();

    this.shop = this.cachedDataService.getShop();
    this.user = this.cachedDataService.getUser();
    this.userSettings = this.cachedDataService.getUserSettings();

    this.isFiscalMode = this.cachedDataService.isFiscalMode();
    this.isDesktop = this.utilsService.isDesktop();

    this.hostListenerService
      .getEventType()
      .subscribe((event: string) => (this.eventType = event));

    this.events.subscribe(SETTINGS_ONLINE_CHECK, (isOnline: boolean) => {
      this.isOnline = isOnline;

      if (
        this.isOnline &&
        this.workingHoursService.haveUnsyncedWorkingHours()
      ) {
        this.events.publish('restart-work-timer', true);
      }
    });

    this.events.subscribe(BARCODE_CHECK, (barcode: string) => {
      this.barcodeHandler(barcode);
    });

    this.events.subscribe('Barcode-sale', (barcode: string) => {
      this.barcodeHandler(barcode);
    });

    this.events.subscribe(PRRO_STATE, (state: ResponsePRROState) => {
      if (state != null) {
        this.prroState = state;

        this.prroStateHandler();

        this.isPrroStateError = false;
      } else {
        this.isPrroStateError = true;
      }
    });

    this.checkOnline({ showToast: false });
    this.initializeSubscriptionData();

    if (this.isDesktop) {
      this.broadcastService =
        this.injector.get<BroadcastService>(BROADCAST_SERVICE);

      this.openClientScreen();
      this.tabControl();
    }
  }

  private cordovaOptions(): void {
    if (!this.platform.is('cordova')) {
      return;
    }

    this.platform.ready().then(() => {
      this.platform.resume.subscribe(() => {
        setTimeout(() => {
          this.scalesService.init(true);
        }, 1500);
      });

      this.platform.backButton.subscribeWithPriority(
        10,
        (processNextHandler) => {
          if (this.location.isCurrentPathEqualTo('/shop')) {
            this.landscapeMenuBackNavigation();
          } else if (this.location.isCurrentPathEqualTo('/weight-product')) {
            this.scalesService.finish();
            processNextHandler();
          } else {
            processNextHandler();
          }
        },
      );
    });
  }

  private landscapeMenuBackNavigation(): void {
    if (this.isLandscapeMode) {
      if (
        this.landscapeMenu.subcategoriesActive &&
        this.landscapeMenu?.subcategories
      ) {
        this.landscapeMenu.subcategories.goBackToMenu.emit();
      } else if (
        this.landscapeMenu.productsActive &&
        this.landscapeMenu?.products
      ) {
        this.landscapeMenu.products.goBackToSubcategories.emit();
      } else if (
        this.landscapeMenu.weightProductActive &&
        this.landscapeMenu?.weightProducts
      ) {
        this.landscapeMenu.weightProducts.goBackToProducts.emit();
      }
    }
  }

  private barcodeHandler(barcode: string): void {
    if (this.searchBarcodeMode) {
      this.searchBarcode = barcode;
    } else if (this.searchProductMode) {
      this.searchProduct = barcode;
    } else if (!this.dialogInProgress && this.isShiftOpened) {
      this.checkProductByBarcode(barcode);
    } else if (!this.isShiftOpened) {
      this.toastService.presentWarning('Відкрийте робочу зміну');
    }
  }

  private async checkProductByBarcode(barcode: string): Promise<void> {
    if (this.requestInProgress) {
      return;
    }

    const filteredProducts =
      barcode.startsWith(WEIGHT_BARCODE_PREFIX) &&
      barcode.length >= BARCODE_EAN_13
        ? this.products.filter(
            (p) => p.plu?.padStart(5, '0') === barcode.substr(2, 5),
          )
        : this.products.filter((p) => p.barcode?.includes(`;${barcode};`));

    const weight =
      barcode.startsWith(WEIGHT_BARCODE_PREFIX) &&
      barcode.length >= BARCODE_EAN_13
        ? Number(barcode.substr(7, 5))
        : undefined;

    if (filteredProducts.length === 1) {
      this.addProduct(filteredProducts[0], weight);
    } else if (filteredProducts.length === 0) {
      this.toastService.presentNoBarcode(barcode);
    } else {
      const modal = await this.modalCtrl.create({
        component: MultibarcodeDialog,
        componentProps: {
          barcode,
          weight,
          items: filteredProducts,
          isProduct: true,
        },
        backdropDismiss: false,
      });

      this.dialogInProgress = true;

      await modal.present();
      await modal.onWillDismiss();

      this.dialogInProgress = false;
    }
  }

  private prroStateHandler(): void {
    if (this.prroState == null) {
      return;
    }

    if (this.prroState.OpenShiftDateTime != null) {
      const shiftDuration = DateTime.fromJSDate(
        this.prroState.OpenShiftDateTime,
      ).diffNow(['days', 'hours']);

      if (Math.abs(shiftDuration.days) > 0) {
        this.prroStateWarning(
          DateTime.now().plus(shiftDuration).setLocale('uk').toRelative() ?? '',
          '\nЗакрийте зміну й відкрийте нову',
        );
      } else if (Math.abs(shiftDuration.hours) > 22) {
        this.prroStateWarning(
          DateTime.now().plus(shiftDuration).setLocale('uk').toRelative() ?? '',
          '\nНе забудьте закрити зміну',
        );
      }
    }
  }

  private prroStateWarning(period: string, recommendation: string = ''): void {
    this.toastService.presentWarning(
      'ПРРО',
      `Фіскальну зміну відкрито ${period}${recommendation}`,
      5000,
      `p-rro`,
    );
  }

  async checkOnline(
    options: { showToast: boolean } = { showToast: true },
  ): Promise<void> {
    const isOnline = await this.utilsService.isOnline();

    if (!isOnline) {
      this.toastService.presentNoInternet();
      return;
    }

    if (!options.showToast) {
      return;
    }

    this.toastService.present('Статус мережі інтернет', 'Активний');

    if (this.salesService.haveUnsyncedSales()) {
      setTimeout(() => {
        this.toastService.presentWarning(
          'Необхідна синхронізація',
          'Знайдено оффлайн дані продажів чи касових операцій',
        );
      });
    }
  }

  private async initializeSubscriptionData(): Promise<void> {
    this.invoices = [];

    for (let i = 0; i < this.shop?.invoiceCount ?? 1; i += 1) {
      this.invoices.push([]);
    }

    this.loadProducts();

    this.events.subscribe(SYNC_PRODUCTS_SUCCESS, () => {
      this.loadProducts();
    });

    this.events.subscribe(
      INVOICE_ADD_PRODUCT,
      (data: { product: Product; weight?: number }) => {
        this.addProduct(data.product, data.weight);
      },
    );

    this.events.subscribe(
      INVOICE_ADD_WEIGHT_PRODUCT,
      (product: SaleProduct) => {
        this.invoices[this.getSlideIndex()].push(product);

        this.scrollToBottom();
        this.updateClientScreen();
      },
    );

    this.events.subscribe(INVOICE_AUTO_SALE, (autoSale: AutoSaleOptions) => {
      this.invoices[this.getSlideIndex()].push(...autoSale.saleProducts);

      this.scrollToBottom();
      this.updateClientScreen();

      if (autoSale.active) {
        setTimeout(async () => {
          if (autoSale.isCard) {
            await this.cardPayment();
          } else {
            await this.cashPayment();
          }
        }, 500);
      }
    });
  }

  private loadProducts(): void {
    this.productsService
      .findForApp({}, { useIndexedDb: true })
      .subscribe((data: Product[]) => (this.products = data));
  }

  private tabControl(): void {
    if (!this.userSettings.tabControl || this.broadcastService == null) {
      return;
    }

    // Listen for visibility change events
    document.addEventListener('visibilitychange', () => {
      // Check if the page is hidden
      if (document.hidden) {
        this.broadcastService?.publish({ type: BROADCAST_TAB_EXIST });
      }
    });

    window.addEventListener('beforeunload', (event) => {
      // Send a message to all other tabs that this tab is closing
      this.broadcastService?.publish({ type: BROADCAST_TAB_CLOSED });
    });

    this.subscription.add(
      this.broadcastService
        .messagesOfType(BROADCAST_TAB_CREATED)
        .subscribe((message) => {
          this.broadcastService?.publish({ type: BROADCAST_TAB_EXIST });
          this.terminalsService.disconnect();
          this.tabConflictAlert();
        }),
    );

    this.subscription.add(
      this.broadcastService
        .messagesOfType(BROADCAST_TAB_EXIST)
        .subscribe((message) => {
          this.tabConflictAlert();
        }),
    );

    this.subscription.add(
      this.broadcastService
        .messagesOfType(BROADCAST_TAB_CLOSED)
        .subscribe((message) => {
          this.toastService.presentWarning(
            'Конфлікт вікон',
            'Сторінку буде оновлено',
          );

          window.location.reload();
        }),
    );

    this.broadcastService.publish({ type: BROADCAST_TAB_CREATED });
  }

  private tabConflictAlert(): void {
    this.alertCtrl
      .create({
        header: 'Конфлікт вікон',
        message: `
          Програма запущена у кількох вкладках або вікнах<br><br>
          Будь ласка, закрийте зайву вкладку чи вікно й оновіть сторінку, яка залишилася<br><br>
          Одночасна робота кількох екземплярів може викликати збої, зокрема у взаємодії з банківським терміналом
        `,
        buttons: [{ text: 'OK', role: 'close' }],
      })
      .then((alert) => alert.present());
  }

  //#region ngOnInit
  ngOnInit(): void {
    setTimeout(() => {
      this.scalesService.init();
    }, 500);

    setTimeout(() => {
      this.terminalsService.init();
    }, 1500);

    this.integrationService.isConnected().subscribe(async (isConnected) => {
      const integration = await this.integrationService.getSettings();

      if (integration.name !== Integration.Eleks || !isConnected) {
        clearInterval(this.integrationTimer);
        return;
      }

      if (isConnected) {
        this.integrationTimer = setInterval(() => {
          if (this.invoices[this.getSlideIndex()].length === 0) {
            this.integrationService.checkNewDocs();
          }
        }, integration.eleksInterval * 1000);
      }
    });
  }

  //#region ngOnDestroy
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  //#region ionViewWillEnter
  ionViewWillEnter(): void {
    this.handleScreenOrientation();
    this.hostListenerService.setEventType('sale');

    this.userSettings = this.cachedDataService.getUserSettings();

    if (!this.userSettings.keyboardMode) {
      this.events.publish(
        SETTINGS_BLUETOOTH_BARCODE_SCANNER,
        KEYBOARD_MODE_DEFAULT_VALUE,
      );
    }

    const prro = this.cachedDataService.getPRRO();

    if (prro == null) {
      this.prroService.selectPRro().then(() => {
        this.isFiscalMode = this.cachedDataService.isFiscalMode();

        this.checkPrroState();
      });
    } else {
      this.checkPrroState();
    }
  }

  private handleScreenOrientation(): void {
    if (this.platform.is('cordova')) {
      this.setCordovaLandscapeMode();

      this.screenOrientation.onChange().subscribe(() => {
        setTimeout(() => {
          this.setCordovaLandscapeMode();
        }, 100);
      });
    } else {
      this.isLandscapeMode = this.platform.isLandscape();

      this.platform.resize.subscribe(() => {
        this.isLandscapeMode = this.platform.isLandscape();

        this.slides.update().then().catch();
      });
    }
  }

  private setCordovaLandscapeMode(): void {
    const orientation = this.screenOrientation.ORIENTATIONS;

    const pluginIsLandscape =
      this.screenOrientation.type === orientation.LANDSCAPE ||
      this.screenOrientation.type === orientation.LANDSCAPE_PRIMARY ||
      this.screenOrientation.type === orientation.LANDSCAPE_SECONDARY;

    const platformIsLandscape = this.platform.isLandscape();
    const platformWidth = this.platform.width();
    const platformHeight = this.platform.height();

    if (
      pluginIsLandscape !== platformIsLandscape &&
      platformWidth > platformHeight
    ) {
      this.isLandscapeMode = platformIsLandscape;
    } else {
      this.isLandscapeMode = pluginIsLandscape;
    }

    this.slides.update().then().catch();
  }

  private checkPrroState(): void {
    if (!this.isFiscalMode) {
      return;
    }

    const cryptData = this.cachedDataService.getCryptData();

    if (cryptData != null && cryptData?.isCorrect() && !this.isPrroStateError) {
      this.prroService.initCrypt(cryptData).then((subject) => {
        if (subject != null) {
          this.prroService.getPRROState().then().catch();
        }
      });
    }
  }

  //#region Search
  checkBarcode(): void {
    if (this.searchBarcode === '') {
      return;
    }

    const barcode = this.searchBarcode;

    this.searchBarcode = '';

    this.checkProductByBarcode(barcode);
  }

  clearBarcodeSearchbar(): void {
    this.searchBarcodeMode = !this.searchBarcodeMode;
    this.searchBarcode = '';
  }

  searchFocus(): void {
    this.isChoseProduct = false;

    this.filterList();
  }

  clearTextSearchbar(): void {
    this.searchProductMode = false;
    this.searchProduct = '';

    setTimeout(() => {
      this.isChoseProduct = true;
      this.filteredProducts = [];
    }, 300);
  }

  filterList(): void {
    if (this.isChoseProduct) {
      return;
    }

    const searchedWords = this.searchProduct.trim().toLowerCase().split(' ');

    this.filteredProducts = this.products.filter((product) => {
      const name = product.name.trim().toLowerCase();

      return (
        !this.searchProduct ||
        searchedWords.every((word) => name.includes(word))
      );
    });
  }

  showBarcodeSearchbar(): void {
    this.searchBarcodeMode = true;

    this.searchbarFocusTimeout();
  }

  showTextSearchbar(): void {
    this.searchProductMode = true;

    this.searchbarFocusTimeout();
  }

  private searchbarFocusTimeout(): void {
    setTimeout(() => {
      if (this.searchbar) {
        this.searchbar.setFocus();
      }
    }, 100);
  }

  //#region Invoice
  getPageIndex(): string {
    return `${this.getSlideIndex() + 1} / ${this.invoices.length}`;
  }

  getSlideIndex(): number {
    // TODO: fix with promises
    return this.slideIndex;
  }

  async slideDidChange(): Promise<void> {
    const index = await this.slides.getActiveIndex();
    const length = await this.slides.length();

    if (index == null) {
      this.slideIndex = 0;
    }

    if (index === length) {
      this.slideIndex = length - 1;
    }

    this.slideIndex = index;

    this.scrollToBottom();
    this.updateClientScreen();
  }

  //#region Products
  incrementQuantity(saleProduct: SaleProduct): void {
    saleProduct.quantity += 1;

    if (saleProduct.product.excise?.needLabel) {
      this.exciseProductDialog(saleProduct);
    }

    this.calcSaleProductCostAndDiscount(saleProduct);
    this.updateClientScreen();
  }

  async inputQuantity(saleProduct: SaleProduct): Promise<void> {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    const inputFieldId = 'inputField';

    const alert = await this.alertCtrl.create({
      header: 'Кількість',
      message: `Введіть ціле число`,
      inputs: [
        {
          id: inputFieldId,
          name: 'saleQuantity',
          placeholder: `Кількість`,
          type: 'number',
          value: saleProduct.quantity,
          min: 1,
          max: MAX_QUANTITY_VALUE,
        },
      ],
      buttons: [
        {
          text: 'Скасувати',
          role: 'cancel',
          cssClass: 'tertiary',
        },
        {
          text: 'Підтвердити',
          role: 'confirm',
          cssClass: 'primary',
          handler: (data: { saleQuantity: string }) => {
            this.updateSaleProductQuantity(saleProduct, data.saleQuantity);
          },
        },
      ],
      cssClass: CONFIRM_DIALOG_ALERT_STYLE,
    });

    alert.addEventListener('ionAlertDidPresent', (e) => {
      const inputField = document.getElementById(inputFieldId);

      if (!inputField) {
        return;
      }

      setTimeout(() => {
        inputField.focus();

        if (inputField instanceof HTMLInputElement) {
          inputField.select();
        }
      }, 100);

      inputField.onkeyup = async (event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (inputField instanceof HTMLInputElement) {
          this.updateSaleProductQuantity(saleProduct, inputField.value);

          await alert.dismiss();
        }
      };
    });

    await alert.present();
  }

  private updateSaleProductQuantity(
    saleProduct: SaleProduct,
    value: string,
  ): void {
    this.hostBarcode = '';

    if (!value) {
      return;
    }

    saleProduct.quantity = Math.min(
      Math.max(1, Math.round(Math.abs(Number(value)))),
      Math.round(MAX_QUANTITY_VALUE) - 1,
    );

    if (saleProduct.product.excise?.needLabel) {
      this.exciseProductDialog(saleProduct);
    }

    this.calcSaleProductCostAndDiscount(saleProduct);
    this.updateClientScreen();
  }

  getWeightProductQuantity(saleProduct: SaleProduct): string {
    return `${+(saleProduct.quantity * WEIGHT_AMOUNT).toFixed(
      3,
    )} ${saleProduct.product.amount.split(`${WEIGHT_AMOUNT}`)[1]?.trim()}`;
  }

  decrementQuantity(saleProduct: SaleProduct): void {
    if (saleProduct.product.weightProduct) {
      pull(this.invoices[this.getSlideIndex()], saleProduct);

      this.updateClientScreen();

      return;
    }

    saleProduct.quantity -= 1;

    if (saleProduct.product.excise?.needLabel && saleProduct.quantity > 0) {
      this.exciseProductDialog(saleProduct, true);
    }

    this.calcSaleProductCostAndDiscount(saleProduct);

    if (saleProduct.quantity === 0) {
      pull(this.invoices[this.getSlideIndex()], saleProduct);
    }

    this.updateClientScreen();
  }

  async addProduct(product: Product, weight?: number): Promise<void> {
    const duplicateProduct = this.checkDuplicate(product);

    if (duplicateProduct != null && !duplicateProduct.product.weightProduct) {
      duplicateProduct.quantity += 1;

      if (product.excise?.needLabel) {
        this.exciseProductDialog(duplicateProduct);
      }

      this.calcSaleProductCostAndDiscount(duplicateProduct);
      this.updateClientScreen();

      return;
    }

    const saleProduct = this.utilsService.getStartSaleProduct(product);

    if (product.excise?.needLabel) {
      this.exciseProductDialog(saleProduct);
    }

    if (product.weightProduct) {
      await this.addWeightProduct(saleProduct, weight);
    } else {
      this.invoices[this.getSlideIndex()].push(saleProduct);
    }

    this.scrollToBottom();
    this.updateClientScreen();
  }

  private checkDuplicate(product: Product): SaleProduct | undefined {
    return this.invoices[this.getSlideIndex()].find(
      (item) => item.product.id === product.id,
    );
  }

  private async addWeightProduct(
    saleProduct: SaleProduct,
    weight?: number,
  ): Promise<void> {
    let scalesData = this.scalesService.emptyData();
    let isScalesAvailable = false;

    if (weight != null) {
      scalesData = this.scalesService.convertWeight(weight);
    } else {
      isScalesAvailable = this.scalesService.isScalesAvailable(
        saleProduct.product.amount,
      );
    }

    if (isScalesAvailable) {
      this.scalesService.getWeight();

      await this.scalesService.wait();

      scalesData = this.scalesService.data();
    }

    if (!scalesData.isError && scalesData.isStable && scalesData.weight > 0) {
      if (isScalesAvailable) {
        this.scalesService.finish();
      }

      this.utilsService.calcWeightProductData(saleProduct, scalesData.weight);
      this.invoices[this.getSlideIndex()].push(saleProduct);
    } else {
      this.weightProductDialog(saleProduct);
    }
  }

  async exciseProductDialog(
    saleProduct: SaleProduct,
    decrement: boolean = false,
  ): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ExciseProductDialog,
      componentProps: {
        saleProduct,
        decrement,
      },
      backdropDismiss: false,
    });

    this.dialogInProgress = true;

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.success) {
      saleProduct.refreshExciseLabelView();
      this.updateClientScreen();
    }

    this.dialogInProgress = false;
  }

  async weightProductDialog(
    saleProduct: SaleProduct,
    isEdit: boolean = false,
  ): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: WeightProductComponent,
      componentProps: {
        isEdit,
        isModal: true,
        editProduct: saleProduct,
      },
      backdropDismiss: false,
    });

    this.dialogInProgress = true;

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data != null) {
      this.updateClientScreen();
    }

    this.dialogInProgress = false;
  }

  async updatePrice(saleProduct: SaleProduct): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ProductPriceDialog,
      componentProps: {
        saleProduct,
      },
      backdropDismiss: false,
    });

    this.dialogInProgress = true;

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.success) {
      this.calcSaleProductCostAndDiscount(saleProduct);
      this.updateClientScreen();
    }

    this.dialogInProgress = false;
  }

  private calcSaleProductCostAndDiscount(saleProduct: SaleProduct): void {
    saleProduct.cost = this.utilsService.cost(
      saleProduct.quantity,
      saleProduct.price,
    );

    saleProduct.fullCost = this.utilsService.cost(
      saleProduct.quantity,
      saleProduct.fullPrice,
    );

    if (Math.abs(saleProduct.fullPrice - saleProduct.price) < ONE_KOP) {
      return;
    }

    saleProduct.personalDiscount = round(
      Math.max(
        0,
        saleProduct.quantity * (saleProduct.fullPrice - saleProduct.price),
      ),
      2,
    );

    saleProduct.discount = saleProduct.personalDiscount;
  }

  isDiffPrice(saleProduct: SaleProduct): boolean {
    return Math.abs(saleProduct.product.price - saleProduct.price) > ONE_KOP;
  }

  private scrollToBottom(): void {
    if (this.productList == null) {
      return;
    }

    setTimeout(() => {
      this.productList?.scrollToBottom();
    }, 100);
  }

  private updateClientScreen(): void {
    this.clientScreenService.update(
      this.shop,
      this.invoices[this.getSlideIndex()],
      this.broadcastService,
    );
  }

  //#region Header Operations
  refreshMenu(): void {
    this.dialogInProgress = false;
    this.requestInProgress = false;
    this.requestCashInProgress = false;
    this.requestCardInProgress = false;

    if (this.isLandscapeMode) {
      this.landscapeMenu.refreshMenu();
    }
  }

  openClientScreen(): void {
    this.clientScreenService.open(
      this.shop,
      this.invoices[this.getSlideIndex()],
      this.broadcastService,
    );
  }

  goToPRROPage(): void {
    this.router.navigateByUrl('/p-rro');
  }

  async openProductsMenu(): Promise<void> {
    const actionSheetButtons: ActionSheetButton[] = [
      {
        text: 'Скасувати',
        icon: 'close',
        role: 'cancel',
      },
    ];

    actionSheetButtons.push({
      text: 'Очистити чек',
      icon: 'trash-outline',
      cssClass: 'clear',
      handler: () => {
        this.alertCtrl
          .create({
            header: 'Чек',
            message: `
            <strong>Видалити</strong> всі (${
              this.invoices[this.getSlideIndex()].length
            }) товари з поточного чеку?`,
            buttons: [
              {
                text: 'Скасувати',
                role: 'cancel',
                cssClass: 'tertiary',
              },
              {
                text: 'Підтвердити',
                role: 'confirm',
                cssClass: 'primary',
                handler: () => {
                  remove(this.invoices[this.getSlideIndex()]);
                },
              },
            ],
            cssClass: CONFIRM_DIALOG_ALERT_STYLE,
          })
          .then((alert) => alert.present());
      },
    });

    const actionSheet = await this.actionSheetController.create({
      header: 'Додаткові операції',
      cssClass: 'invoices-action-sheet-controller',
      buttons: actionSheetButtons,
    });

    await actionSheet.present();
  }

  //#region Footer Operations
  async openCalculation(): Promise<void> {
    const isInvalidInvoice = await this.invalidInvoice();

    if (isInvalidInvoice || this.requestInProgress) {
      return;
    }

    this.requestInProgress = true;

    let totalDiscount = 0;

    const saleProducts: SaleProduct[] = [];

    this.invoices[this.getSlideIndex()].forEach((invoiceItem) => {
      const saleProduct = this.utilsService.getSaleProduct(invoiceItem);

      totalDiscount += saleProduct.discount ?? 0;

      saleProducts.push(saleProduct);
    });

    const modal = await this.modalCtrl.create({
      component: SaleCalculationDialog,
      componentProps: {
        saleProducts,
        broadcastService: this.broadcastService,
        saleWithDiscount: Boolean(totalDiscount),
      },
      backdropDismiss: false,
      cssClass: 'payment-dialog',
      id: SALE_CALCULATION_DIALOG_ID,
    });

    this.dialogInProgress = true;

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.isSuccess) {
      remove(this.invoices[this.getSlideIndex()]);

      if (this.broadcastService != null && this.shop.clientScreen) {
        this.broadcastService.publish({ type: BROADCAST_SALE_SOLD });
        this.updateClientScreen();
      }
    }

    this.dialogInProgress = false;
    this.requestInProgress = false;
  }

  async cashPayment(): Promise<void> {
    const isInvalidInvoice = await this.invalidInvoice({
      checkCashAmount: true,
    });

    if (isInvalidInvoice) {
      return;
    }

    this.quickPayment(false);
  }

  async cardPayment(): Promise<void> {
    const isInvalidInvoice = await this.invalidInvoice();

    if (isInvalidInvoice) {
      return;
    }

    let paymentAmount = 0;

    this.invoices[this.getSlideIndex()].forEach((invoiceItem) => {
      paymentAmount += invoiceItem.cost;
    });

    this.dialogInProgress = true;

    const cardPayment = await this.salesService.cashlessPaymentDialog(
      paymentAmount,
    );

    this.dialogInProgress = false;

    if (cardPayment == null) {
      return;
    }

    this.quickPayment(true, cardPayment);
  }

  private async quickPayment(
    isCard: boolean,
    cardPayment?: SalePayment,
  ): Promise<void> {
    if (this.requestInProgress) {
      return;
    }

    const slideIndex = this.getSlideIndex();

    if (this.invoices[slideIndex].length === 0) {
      return;
    }

    this.requestInProgress = true;
    this.requestCashInProgress = !isCard;
    this.requestCardInProgress = isCard;

    const sale = new Sale();

    sale.cashless = isCard;
    sale.saleProducts = [];

    this.invoices[slideIndex].forEach((invoiceItem) => {
      const saleProduct = this.utilsService.getSaleProduct(invoiceItem);

      sale.saleProducts.push(saleProduct);

      sale.totalDiscount += invoiceItem.personalDiscount ?? 0;
      sale.discountSum += invoiceItem.discount ?? 0;
      sale.costSum += invoiceItem.fullCost;
    });

    remove(this.invoices[slideIndex]);

    sale.costSum = round(sale.costSum, 2);

    const payment = new SalePayment();

    if (isCard) {
      sale.roundSum = 0;
      sale.paymentSum = round(sale.costSum - sale.discountSum, 2);
      sale.cashSum = 0;
      sale.cardSum = sale.paymentSum;

      payment.method = PayFormCode.Card;
      payment.amount = sale.paymentSum;

      if (cardPayment != null) {
        payment.acquirerName = cardPayment.acquirerName;
        payment.acquirerTransactionId = cardPayment.acquirerTransactionId;
        payment.authCode = cardPayment.authCode;
        payment.deviceId = cardPayment.deviceId;
        payment.epzDetails = cardPayment.epzDetails;
        payment.paymentSystem = cardPayment.paymentSystem;
        payment.posTransactionDate = cardPayment.posTransactionDate;
        payment.posTransactionNumber = cardPayment.posTransactionNumber;
        payment.transactionId = cardPayment.transactionId;
        payment.signVerification = cardPayment.signVerification;
        payment.operationType = cardPayment.operationType;
      }
    } else {
      sale.paymentSum = round(sale.costSum - sale.discountSum, 2);

      sale.roundSum = this.utilsService.getRoundSum(sale.paymentSum);
      sale.paymentSum = round(sale.paymentSum + sale.roundSum, 2);
      sale.cashSum = sale.paymentSum;
      sale.cardSum = 0;

      payment.method = PayFormCode.Cash;
      payment.amount = sale.paymentSum;
    }

    sale.salePayments = [payment];

    sale.calcTotalTaxes();

    this.salesService
      .createSale(sale, CheckDocumentSubType.CheckGoods, {
        needFiscalization: this.shop.prroManualControl
          ? isCard
            ? true
            : this.userSettings.fiscalByDefaultMode
          : true,
      })
      .then(() => {
        if (this.broadcastService != null && this.shop.clientScreen) {
          this.broadcastService.publish({ type: BROADCAST_SALE_SOLD });
          this.updateClientScreen();
        }

        this.requestInProgress = false;
        this.requestCashInProgress = false;
        this.requestCardInProgress = false;
      });
  }

  private async invalidInvoice(
    options: { checkCashAmount: boolean } = { checkCashAmount: false },
  ): Promise<boolean> {
    for (const item of this.invoices[this.getSlideIndex()]) {
      if (item.quantity <= 0) {
        const zeroAlert = await this.alertCtrl.create({
          header: 'Помилка формування чеку',
          message: `Неправильне значення поля "кількість"<br><br>
              <b>${item.product.name}</b><br>
              кількість: <b>${item.quantity}</b><br><br>
              Виправте значення й повторіть спробу`,
          buttons: [{ text: 'Закрити', role: 'close' }],
        });

        await zeroAlert.present();

        return true;
      }
    }

    if (options.checkCashAmount) {
      return this.utilsService.cashLimit(
        Math.max(this.totals.amount, this.totals.roundedAmount),
      );
    }

    return false;
  }
}
