import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';

import { SelectFromTable } from './select-from-table.interface';

@Component({
  selector: 'bk-select-from-table-dialog',
  templateUrl: './select-from-table.dialog.html',
  styleUrls: ['./select-from-table.dialog.scss'],
})
export class SelectFromTableDialog implements OnInit {
  @ViewChild(IonSearchbar) searchbar: IonSearchbar;

  @Input() title: string;
  @Input() items: SelectFromTable[];

  searchProductMode = false;
  searchItem = '';

  filteredItems: SelectFromTable[] = [];

  constructor(private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.filteredItems = [...this.items];
  }

  ionViewDidEnter(): void {
    this.setFocusOnInput();
  }

  private setFocusOnInput(): void {
    setTimeout(() => {
      if (this.searchbar != null) {
        this.searchbar.setFocus();
      }
    }, 100);
  }

  selectAll(): void {
    this.searchbar.getInputElement().then((data) => {
      data.select();
    });
  }

  clearTextSearchbar(): void {
    this.searchItem = '';

    setTimeout(() => {
      this.filteredItems = [...this.items];
    }, 300);
  }

  filterList(): void {
    const searchedWords = this.searchItem.trim().toLowerCase().split(' ');

    this.filteredItems = this.items.filter((item) => {
      const name = item.name.trim().toLowerCase();

      return (
        !this.searchItem || searchedWords.every((word) => name.includes(word))
      );
    });
  }

  async select(item: SelectFromTable): Promise<void> {
    await this.modalCtrl.dismiss({ item });
  }

  async cancel(): Promise<void> {
    await this.modalCtrl.dismiss({ item: null });
  }
}
