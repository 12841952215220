import { HttpResponse } from '@angular/common/http';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Platform } from '@ionic/angular';
import entries from 'lodash-es/entries';
import { defer, of, throwError, Observable } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import 'url-search-params-polyfill';

import {
  DEFAULT_CASHBOX_URL,
  INDEXEDDB_TABLE_DATA,
} from '../../settings/settings.const';
import { Category } from '../../shop/categories/category.model';
import { Product } from '../../shop/products/product.model';
import { Subcategory } from '../../shop/subcategories/subcategory.model';
import { NO_INTERNET_CONNECTION } from '../constants/error-messages.const';
import { IHttp } from '../interfaces/ihttp';
import { ObjectLiteral } from '../interfaces/object-literal';
import { StorageTable } from '../models/storage-table.model';

import { Resource } from './resource';

type ItemWithCover = Category | Subcategory | Product;

interface SkipCacheRule {
  path: string;
  params: string[];
}

const skipCacheRules: SkipCacheRule[] = [
  { path: 'clients', params: ['query'] },
];

export abstract class CachedResource<T> extends Resource<T> {
  cache?: boolean;
  preferCache?: boolean;

  private data: StorageTable;

  constructor(
    protected http: IHttp,
    protected platform: Platform,
    protected network: Network,
    protected config: { path: string; cache?: boolean; preferCache?: boolean },
  ) {
    super(http, config);

    this.cache = config.cache;
    this.preferCache = config.cache;

    this.data = new StorageTable(INDEXEDDB_TABLE_DATA);
  }

  async saveData(key: string, value: T[] | undefined): Promise<void> {
    await this.data.set<T[] | undefined>(key, value);
  }

  async getData(key: string): Promise<string | null> {
    const data = await this.data.get<T[]>(key);

    if (data != null) {
      return JSON.stringify(data);
    }

    return localStorage.getItem(key) ?? JSON.stringify([]);
  }

  findForApp(
    params: Partial<T> | ObjectLiteral = {},
    customOptions?: { forceRefresh?: boolean; useIndexedDb?: boolean },
  ): Observable<T[]> {
    const isOffline = this.getOfflineStatus();
    const urlSearchParams = new URLSearchParams();

    entries(params).forEach((entry) => {
      urlSearchParams.append(entry[0], entry[1]);
    });

    const url = `${this.buildUrl()}/app?${urlSearchParams.toString()}`;

    if (isOffline) {
      return this.localDataObservable(url, customOptions?.useIndexedDb).pipe(
        switchMap((data) => {
          if (data == null) {
            const response = new HttpResponse({
              body: JSON.stringify({ message: NO_INTERNET_CONNECTION }),
              status: 0,
              url: this.buildUrl(),
            });

            return throwError(response);
          }

          return of(this.addURL(url, data));
        }),
      );
    }

    if (
      customOptions == null ||
      (!customOptions.forceRefresh && this.preferCache)
    ) {
      return this.localDataObservable(url, customOptions?.useIndexedDb).pipe(
        switchMap((data) => {
          if (data != null) {
            return of(this.addURL(url, data));
          }

          return this.remoteDataObservable(url, params, customOptions);
        }),
      );
    }

    return this.remoteDataObservable(url, params, customOptions);
  }

  private localDataObservable(
    url: string,
    useIndexedDb: boolean = false,
  ): Observable<string | null> {
    if (useIndexedDb) {
      return defer(() => this.getData(url));
    }

    return of(localStorage.getItem(url));
  }

  private remoteDataObservable(
    url: string,
    params: Partial<T> | ObjectLiteral = {},
    customOptions?: { forceRefresh?: boolean; useIndexedDb?: boolean },
  ): Observable<T[]> {
    return super
      .findForApp(params)
      .pipe(
        concatMap((data) => {
          if (this.cache) {
            data.forEach((item) => {
              if (this.isProduct(item)) {
                item.barcode = `;${item.barcode ?? ''};`;
              }
            });

            return this.saveRemoteData(url, data, customOptions);
          }

          return of(data);
        }),
      )
      .pipe(
        concatMap((data) => {
          return of(data);
        }),
      );
  }

  private async saveRemoteData(
    url: string,
    data: T[],
    customOptions?: { forceRefresh?: boolean; useIndexedDb?: boolean },
  ): Promise<T[]> {
    if (this.skipLocalSaving(url)) {
      return data;
    }

    if (customOptions?.useIndexedDb) {
      await this.saveData(url, data);
    } else {
      localStorage.setItem(url, JSON.stringify(data));
    }

    return data;
  }

  private addURL(url: string, data: string): any[] {
    const arrayData: any[] = JSON.parse(data);

    if (arrayData.length) {
      arrayData[0].storageUrl = url;
    }

    return arrayData;
  }

  private getOfflineStatus(): boolean {
    return this.platform.is('cordova')
      ? this.network.type === 'none'
      : !navigator.onLine;
  }

  private isProduct(item: ItemWithCover | T): item is Product {
    return (item as Product).barcode !== undefined;
  }

  private skipLocalSaving(url: string): boolean {
    try {
      const fullUrl = new URL(url, DEFAULT_CASHBOX_URL);

      const urlPath = fullUrl.pathname;
      const urlParams = fullUrl.searchParams;

      return skipCacheRules.some((rule) => {
        const hasPath = urlPath.includes(`/${rule.path}`);
        const hasParams = rule.params.some((param) => urlParams.has(param));

        return hasPath && hasParams;
      });
    } catch {
      return false;
    }
  }
}
