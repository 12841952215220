import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ImageLoaderService } from 'ionic-image-loader-v5';
import cloneDeep from 'lodash-es/cloneDeep';
import { Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { BROADCAST_SERVICE } from '../core/constants/broadcast-token.const';
import {
  BROADCAST_PING,
  BROADCAST_PONG,
  BROADCAST_SALE_CALCULATION,
  BROADCAST_SALE_INVOICE,
  BROADCAST_SALE_SOLD,
} from '../core/constants/events.const';
import { WEIGHT_AMOUNT } from '../core/constants/product.const';
import { ShopClientScreenSlide } from '../core/models/shop-client-screen-slide.model';
import { Shop } from '../core/models/shop.model';
import { BroadcastService } from '../core/services/broadcast.service';
import { CachedDataService } from '../core/services/cached-data.service';
import { ShopsService } from '../core/services/resources/shops.service';
import { SaleProduct } from '../sales/sale/sale-product.model';
import { PRRO_NAME } from '../settings/settings.const';
import { InvoiceProductsTotals } from '../shared/components/invoice-products/invoice-products.interface';
import { InvoiceProductsService } from '../shared/components/invoice-products/invoice-products.service';
import { ISaleCalculationSummary } from '../shop/sale-calculation/sale-calculation-summary.interface';

import { fade } from './animations';

@Component({
  selector: 'bk-client-screen',
  templateUrl: './client-screen.component.html',
  styleUrls: ['./client-screen.component.scss'],
  animations: [fade],
})
export class ClientScreenComponent implements OnInit, OnDestroy {
  shop: Shop;
  saleProducts: SaleProduct[] = [];
  summary?: ISaleCalculationSummary;

  private slides: ShopClientScreenSlide[] = [];
  private counter = 0;
  private enableAnimation = false;

  slidePath = '';
  state = 'in';

  isThanks = false;
  PRRO_NAME = PRRO_NAME;

  private subscription = new Subscription();

  // Returns a Promise that resolves after "ms" Milliseconds
  timer = (ms: number) => new Promise((res) => setTimeout(res, ms));

  get totals(): InvoiceProductsTotals {
    return this.invoiceProductsService.getTotals(this.saleProducts);
  }

  constructor(
    @Inject(BROADCAST_SERVICE) private broadcastService: BroadcastService,
    private changeDetector: ChangeDetectorRef,
    private cachedDataService: CachedDataService,
    private invoiceProductsService: InvoiceProductsService,
    private shopsService: ShopsService,
    protected imageLoader: ImageLoaderService,
  ) {
    this.shop = this.cachedDataService.getShop();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.broadcastService
        .messagesOfType(BROADCAST_SALE_INVOICE)
        .subscribe((message) => {
          this.summary = undefined;
          this.saleProducts =
            message.payload != null
              ? cloneDeep(message.payload as SaleProduct[])
              : [];
        }),
    );

    this.subscription.add(
      this.broadcastService
        .messagesOfType(BROADCAST_SALE_SOLD)
        .subscribe((message) => {
          this.summary = undefined;
          this.isThanks = true;

          setTimeout(() => {
            this.isThanks = false;
          }, 7500);
        }),
    );

    this.subscription.add(
      this.broadcastService
        .messagesOfType(BROADCAST_PING)
        .subscribe((message) => {
          this.broadcastService.publish({ type: BROADCAST_PONG });
        }),
    );

    this.subscription.add(
      this.broadcastService
        .messagesOfType(BROADCAST_SALE_CALCULATION)
        .subscribe((message) => {
          if (message.payload) {
            this.summary = cloneDeep(
              message.payload as ISaleCalculationSummary,
            );
          } else {
            this.summary = undefined;
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ionViewWillEnter(): void {
    this.shopsService.findClientScreenSlides().subscribe((slides) => {
      this.slides = slides;

      this.selectRandomSlide();
    });

    setTimeout(async () => {
      while (true) {
        this.enableAnimation = true;
        this.counter = 0;

        this.toggleState();

        await this.timer(25 * 1000); // then the created Promise can be awaited
      }
    }, 1000);
  }

  getProductQuantity(saleProduct: SaleProduct): string {
    if (saleProduct.product.weightProduct) {
      return `${+(saleProduct.quantity * WEIGHT_AMOUNT).toFixed(
        3,
      )} ${saleProduct.product.amount.split(`${WEIGHT_AMOUNT}`)[1]?.trim()}`;
    }

    return `${saleProduct.quantity.toString()} шт`;
  }

  onDone($event: any): void {
    if (this.enableAnimation) {
      if (this.counter === 1) {
        this.selectRandomSlide();
      }

      this.toggleState();
    }
  }

  private toggleState(): void {
    if (this.counter < 2) {
      this.state = this.state === 'in' ? 'out' : 'in';
      this.counter += 1;

      this.changeDetector.detectChanges();
    }
  }

  private selectRandomSlide(): void {
    const minValue = Math.ceil(0);
    const maxValue = Math.floor(this.slides.length);
    const index = Math.floor(Math.random() * (maxValue - minValue)) + minValue;
    const slide = this.slides[index];

    this.slidePath = slide ? this.getSlidePath(slide) : '';

    this.changeDetector.detectChanges();
  }

  private getSlidePath(slide: ShopClientScreenSlide): string {
    return `${environment.apiUrl}/shops/${slide.shopId}/client-screen-slide/${slide.slidePath}`;
  }
}
