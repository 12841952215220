<ion-app>
  <ion-split-pane contentId="main-content" when="(min-width: 3840px)">
    <ion-menu contentId="main-content" type="reveal" [disabled]="!isAuthenticated()">
      <ion-header>
        <ion-toolbar>
          <div class="logo-container">
            <img class="header-logo" src="assets/images/logo.png">
          </div>

          <div>
            <ion-note slot="end">v. {{version}}</ion-note>
          </div>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-list id="menu-list">
          <ion-menu-toggle>
            <ion-item routerLink="/shop" routerLinkActive="selected" detail="false">
              <bk-icon slot="start" name="home"></bk-icon>
              <ion-label>Головна</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item [button]="true" (click)="toggleWarehouse = !toggleWarehouse" detail="false">
            <bk-icon slot="start" name="supply"></bk-icon>
            <ion-label>Склад</ion-label>
            <bk-icon slot="end" name="arrow-down" [ngClass]="{'reverse-icon': toggleWarehouse}"></bk-icon>
          </ion-item>

          <ion-list *ngIf="toggleWarehouse" id="sync-list" class="sub-list">
            <ion-menu-toggle [autoHide]="false">
              <ion-item routerLink="/supplies" routerLinkActive="selected" detail="false">
                <ion-icon slot="start" name="cloud-outline" class="sync-subicons"></ion-icon>
                <ion-label>Залишки товарів</ion-label>
              </ion-item>

              <ion-item routerLink="/refills" routerLinkActive="selected" detail="false">
                <ion-icon slot="start" name="cloud-upload-outline" class="sync-subicons"></ion-icon>
                <ion-label>Надходження</ion-label>
              </ion-item>

              <ion-item routerLink="/returns" routerLinkActive="selected" detail="false">
                <ion-icon slot="start" name="cloud-download-outline" class="sync-subicons"></ion-icon>
                <ion-label>Повернення</ion-label>
              </ion-item>

              <ion-item routerLink="/wastes" routerLinkActive="selected" detail="false">
                <ion-icon slot="start" name="cloud-offline-outline" class="sync-subicons"></ion-icon>
                <ion-label>Списання</ion-label>
              </ion-item>

              <ion-item routerLink="/moves" routerLinkActive="selected" detail="false">
                <ion-icon slot="start" name="rainy-outline" class="sync-subicons"></ion-icon>
                <ion-label>Переміщення</ion-label>
              </ion-item>

              <ion-item routerLink="/orders" routerLinkActive="selected" detail="false">
                <ion-icon slot="start" name="cloud-done-outline" class="sync-subicons"></ion-icon>
                <ion-label>Замовлення</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-menu-toggle>
            <ion-item routerLink="/revision" routerLinkActive="selected" detail="false">
              <bk-icon slot="start" name="search"></bk-icon>
              <ion-label>Ревізія</ion-label>
            </ion-item>

            <ion-item (click)="navigateTo('/sales')" routerLinkActive="selected" detail="false">
              <bk-icon slot="start" name="money"></bk-icon>
              <ion-label>Продажі</ion-label>
            </ion-item>

            <ion-item (click)="navigateTo('/presales')" routerLinkActive="selected" detail="false" *ngIf="shop?.presales">
              <bk-icon slot="start" name="alarm"></bk-icon>
              <ion-label>Відкладені чеки</ion-label>
            </ion-item>

            <ion-item (click)="navigateTo('/transactions')" routerLinkActive="selected" detail="false">
              <bk-icon slot="start" name="transactions"></bk-icon>
              <ion-label>Каса</ion-label>
            </ion-item>

            <ion-item routerLink="/clients" routerLinkActive="selected" detail="false" *ngIf="user?.saleInDebt">
              <ion-icon slot="start" name="person-outline" color="dark"></ion-icon>
              <ion-label>Клієнти</ion-label>
            </ion-item>

            <ion-item routerLink="/statistics" routerLinkActive="selected" detail="false" *ngIf="user?.statistics">
              <bk-icon slot="start" name="statistic"></bk-icon>
              <ion-label>Статистика</ion-label>
            </ion-item>

            <ion-item routerLink="/p-rro" routerLinkActive="selected" detail="false" *ngIf="isFiscalMode">
              <bk-icon slot="start" name="rro"></bk-icon>
              <ion-label>Програмний РРО</ion-label>
            </ion-item>

            <ion-item [button]="true" (click)="openShift()" detail="false" *ngIf="!isShiftOpened">
              <bk-icon slot="start" name="play"></bk-icon>
              <ion-label>Відкрити зміну</ion-label>
            </ion-item>

            <ion-item [button]="true" (click)="closeShift()" detail="false" *ngIf="isShiftOpened && isShiftUser">
              <bk-icon slot="start" name="pause"></bk-icon>
              <ion-label>Закрити зміну</ion-label>
            </ion-item>

            <ion-item [button]="true" (click)="openShift(true)" detail="false" *ngIf="isShiftOpened && !isShiftUser">
              <bk-icon slot="start" name="play"></bk-icon>
              <ion-label>Приєднатися до зміни</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-item [button]="true" (click)="toggleSync = !toggleSync" detail="false" [ngClass]="{'need-synchronize': needSynchronize}">
            <bk-icon slot="start" name="sync"></bk-icon>
            <ion-label>Синхронізація</ion-label>
            <bk-icon slot="end" name="arrow-down" [ngClass]="{'reverse-icon': toggleSync}"></bk-icon>
          </ion-item>

          <ion-list *ngIf="toggleSync" id="sync-list" class="sub-list">
            <ion-menu-toggle [autoHide]="false">
              <ion-item [button]="true" (click)="syncData()" detail="false" [disabled]="syncRequestInProgress" [ngClass]="{'need-synchronize': needSynchronize}">
                <ion-icon slot="start" name="checkmark-sharp" class="sync-subicons"></ion-icon>
                <ion-label>Продажі та каса</ion-label>
              </ion-item>

              <ion-item [button]="true" (click)="syncProducts()" detail="false" [disabled]="syncMenuRequestInProgress">
                <ion-icon slot="start" name="checkmark-sharp" class="sync-subicons"></ion-icon>
                <ion-label>Товари та послуги</ion-label>
              </ion-item>

              <ion-item [button]="true" (click)="syncClients()" detail="false" [disabled]="syncMenuRequestInProgress">
                <ion-icon slot="start" name="checkmark-sharp" class="sync-subicons"></ion-icon>
                <ion-label>Клієнти</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-menu-toggle>
            <ion-item routerLink="/settings" routerLinkActive="selected" detail="false">
              <bk-icon slot="start" name="setting"></bk-icon>
              <ion-label>Налаштування</ion-label>
            </ion-item>

            <ion-item [button]="true" (click)="logout()" detail="false">
              <bk-icon slot="start" name="logout"></bk-icon>
              <ion-label>Вихід</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <div class="shop-name">
            {{ shop?.name }}
          </div>

          <div class="employee-name">
            {{ user?.name }}
          </div>

          <div class="working-time">
            <div class="working-time__container">
              <ng-container *ngIf="isShiftUser; else needShift">
                <span>Відпрацьовано часу:</span>

                <ion-button color="primary" (click)="toggleWorkingInterval()">
                  <ion-icon slot="start" [name]="isWorkStarted ? 'pause' : 'play'"></ion-icon>
                  <ion-label>{{ formatTime(workingTime) }}</ion-label>
                </ion-button>
              </ng-container>

              <ng-template #needShift>
                <span>Для початку роботи потрібно {{ isShiftOpened ? 'приєднатися до робочої зміни' : 'відкрити робочу зміну'}}</span>
              </ng-template>
            </div>
          </div>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
