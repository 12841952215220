export class Client {
  id: number;
  name: string;
  mobilePhone: string;
  email: string;

  // Add field
  bonus?: number;
  balance?: number;
  salesBalance?: number;
}
